import React from 'react';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

import { Typography, Tooltip } from '@material-ui/core';
import { useAddressFromGeocode } from './useAddressFromGeocode';

export const AddressFromGeocodeSection = ({
  latitude,
  longitude,
  mapToken,
  id,
  rowsWithVisibleAddressIds,
  setRowsWithVisibleAddressIds,
}) => {
  const classes = useStyles();

  const { handleClick, error, address, showAddress, isLoading, disabled } = useAddressFromGeocode({
    mapToken,
    latitude,
    longitude,
    rowsWithVisibleAddressIds,
    setRowsWithVisibleAddressIds,
    id,
  });

  return (
    <Box
      key={id}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      onClick={handleClick}
    >
      {showAddress ? (
        <Typography variant="caption" className={classes.address}>
          {!isLoading ? (error ? T.translate(error) : address) : null}
        </Typography>
      ) : (
        <Box className={classes.coordinates}>
          <Box display="flex" justifyContent="center">
            {latitude}
          </Box>
          <Box display="flex" justifyContent="center">
            {longitude}
          </Box>
        </Box>
      )}
      <TooltipComponent disabled={disabled}>
        <Box className={[classes.showAddressButton, disabled && classes.disabled]} disabled={disabled}>
          {showAddress ? T.translate(LocaleKeys.labels.showGeocode) : T.translate(LocaleKeys.labels.showAddress)}
        </Box>
      </TooltipComponent>
    </Box>
  );
};

const TooltipComponent = ({ disabled, children }) => {
  const classes = useStyles();

  if (!disabled) {
    return <>{children}</>;
  }

  return (
    <Tooltip
      title={T.translate(LocaleKeys.labels.translateAddressUpToXDevices, { count: 5 })}
      className={classes.tooltip}
      placement="top"
    >
      {children}
    </Tooltip>
  );
};

AddressFromGeocodeSection.propTypes = {
  onGeocodeChange: PropTypes.func,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  mapToken: PropTypes.string,
  disabled: PropTypes.bool,
};

AddressFromGeocodeSection.defaultProps = {
  disabled: false,
  tooltipText: '',
};

const useStyles = makeStyles((theme, disabled) => ({
  address: {
    ...theme.typography.body1,
    fontSize: '10px',
    lineHeight: '12px',
    display: '-webkit-box',
    lineClamp: 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'normal',
    textAlign: 'center',
    width: '177px',
  },
  coordinates: {
    ...theme.typography.body1,
    fontSize: '10px',
    lineHeight: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  showAddressButton: {
    minWidth: '177px',
    backgroundColor: 'transparent',
    height: '12px',
    color: '#2C2C54',
    fontWeight: 500,
    textDecoration: 'underline',
    cursor: disabled ? 'pointer' : 'default',
    fontSize: '10px',
    lineHeight: '12px',
  },
  disabled: {
    color: '#CBCED3',
  },
  tooltip: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
