import { useState } from 'react';
import { deleteAlarmGroup } from 'Api/devices';

export const useDeleteDialogActions = ({ setAlarmGroups }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [itemToHandle, setItemToHandle] = useState(null);

  const onOpen = ({ id }) => {
    setDialogOpen(true);
    setItemToHandle(id);
  };

  const onConfirm = () =>
    deleteAlarmGroup(itemToHandle).then(() => {
      setAlarmGroups((prev) => prev?.filter((group) => group.externalId !== itemToHandle));
      setItemToHandle(null);
      setDialogOpen(false);
    });

  const onCancel = () => {
    setItemToHandle(null);
    setDialogOpen(false);
  };

  return {
    dialogOpen,
    onOpen,
    itemToHandle,
    onConfirm,
    onCancel,
  };
};
