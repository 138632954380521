import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { Box, styled } from '@material-ui/core';
import FormikCollection from 'Components/inputs/CollectionManager/FormikCollection';
import { TextField } from 'Components/formik/fields';
import Dropdown from 'Components/inputs/Dropdown';
import { withField } from 'Components/formik/formikWrappers';
import { COMPARTMENT_NUMBERS_VARIABLES } from 'Constants/CompartmentNumberVariables';
import { COMPARTMENT_STATE_VARIABLES } from 'Constants/CompartmentStateVariables';

const TextFieldStyled = styled(TextField)({
  minWidth: 300,
});

const DropdownStyled = styled(Dropdown)({
  minWidth: 300,
  marginTop: 8,
});

const DropdownField = withField(DropdownStyled);

const GeckoEventKeywords = (props) => {
  if (!props.data.mappings?.keywordMappings) {
    return <div>{T.translate(LocaleKeys.messages.noDeviceLibrarySelected)}</div>;
  }
  if (!props.data.mappings?.keywordMappings) {
    return <div>{T.translate(LocaleKeys.messages.noInputsMappingsForSelectedDriver)}</div>;
  }

  return (
    <Box margin={'10px 3%'}>
      <FormikCollection
        label={T.translate(LocaleKeys.labels.keywordNames)}
        name="mappings.keywordMappings"
        readOnly={props.data.brokenAlarmsLoading}
      >
        <TextFieldStyled
          label={T.translate(LocaleKeys.labels.keywordName)}
          name="name"
          fullWidth
          readOnly={props.data.brokenAlarmsLoading}
        />
        <TextFieldStyled
          label={T.translate(LocaleKeys.labels.keywordString)}
          name="inputValue"
          fullWidth
          readOnly={props.data.brokenAlarmsLoading}
        />
        <DropdownField
          name="compartmentNumber"
          label={T.translate(LocaleKeys.labels.compartmentNumber)}
          data={COMPARTMENT_NUMBERS_VARIABLES}
          displayField="name"
          valueField="value"
          withoutEmpty
          fullWidth
          translateLabel
          clearSection
        />

        <DropdownField
          name="compartmentState"
          label={T.translate(LocaleKeys.labels.state)}
          data={COMPARTMENT_STATE_VARIABLES}
          displayField="name"
          valueField="value"
          withoutEmpty
          fullWidth
          translateLabel
          clearSection
        />
      </FormikCollection>
    </Box>
  );
};

GeckoEventKeywords.propTypes = {
  data: PropTypes.object,
  brokenAlarmsLoading: PropTypes.bool,
};

export default GeckoEventKeywords;
