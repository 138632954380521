import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { compareObjects } from 'Helpers/ObjectHelper';

const ItemWrapper = styled(Box)({
  height: 64,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  boxShadow: `0px 1px 2px 0px rgba(66, 68, 90, 0.35)`,
  borderRadius: 6,
  marginBottom: 16,
  border: (props) => (props.selected ? '1px solid #3f51b5' : 'none'),
  '&:hover': {
    background: '#eee',
  },
});

export const AlarmGroupItem = ({ onClick, group, selected }) => {
  return (
    <ItemWrapper
      selected={selected}
      onClick={() => {
        onClick?.((previousGroup) => {
          if (compareObjects(previousGroup, group)) {
            return null;
          } else {
            return group;
          }
        });
      }}
    >
      <Typography variant="body1">{group.name}</Typography>
    </ItemWrapper>
  );
};
