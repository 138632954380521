import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import Dropdown from 'Components/inputs/Dropdown';
import { TextField } from 'Components/formik/fields';
import FormikCollection from 'Components/inputs/CollectionManager/FormikCollection';
import { withField } from 'Components/formik/formikWrappers';
import { styled } from '@material-ui/core';
import { COMPARTMENT_NUMBERS_VARIABLES } from 'Constants/CompartmentNumberVariables';
import { COMPARTMENT_STATE_VARIABLES } from 'Constants/CompartmentStateVariables';

const TextFieldStyled = styled(TextField)({
  minWidth: 300,
  margin: 2,
});

const DropdownStyled = styled(Dropdown)({
  minWidth: 300,
  margin: 2,
});

const DropdownField = withField(DropdownStyled);

const GeckoEventKeywordMapping = ({ dictionaries, keywordsEnabled }) => {
  return (
    <FormikCollection
      label={T.translate(LocaleKeys.labels.eventKeywordsMapping)}
      name={'keywordMappings'}
      disableAddItem={!keywordsEnabled}
    >
      <TextFieldStyled label={T.translate(LocaleKeys.labels.keywordName)} name="name" fullWidth />
      <TextFieldStyled label={T.translate(LocaleKeys.labels.keywordString)} name="inputValue" fullWidth />
      <DropdownField
        name="compartmentNumber"
        label={T.translate(LocaleKeys.labels.compartmentNumber)}
        data={COMPARTMENT_NUMBERS_VARIABLES}
        displayField="name"
        valueField="value"
        withoutEmpty
        fullWidth
        translateLabel
        clearSection
      />

      <DropdownField
        name="compartmentState"
        label={T.translate(LocaleKeys.labels.state)}
        data={COMPARTMENT_STATE_VARIABLES}
        displayField="name"
        valueField="value"
        withoutEmpty
        fullWidth
        translateLabel
        clearSection
      />

      <DropdownStyled
        name="variable"
        label={T.translate(LocaleKeys.labels.variablesList)}
        data={dictionaries.eventKeywordsRelatedVariables}
        displayField="name"
        valueField="value"
        withoutEmpty
        fullWidth
        translateLabel
        itemsDisabled
      />
    </FormikCollection>
  );
};

GeckoEventKeywordMapping.propTypes = {
  dictionaries: PropTypes.object,
  keywordsEnabled: PropTypes.bool,
};

export default GeckoEventKeywordMapping;
