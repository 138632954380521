import React from 'react';
import DeleteDialog from 'Components/dialogs/DeleteDialog';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import PropTypes from 'prop-types';

export const DeleteAlarmGroupDialog = ({ disableConfirm, isOpen, onCancel, onConfirm }) => {
  return (
    <DeleteDialog
      isOpen={isOpen}
      onConfirm={onConfirm}
      onCancel={onCancel}
      disableConfirm={disableConfirm}
      title={T.translate(LocaleKeys.messages.deleteAlarmGroupDialogTitle)}
      message={T.translate(LocaleKeys.messages.deleteAlarmGroupDialogMessage)}
    />
  );
};

DeleteAlarmGroupDialog.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  disableConfirm: PropTypes.bool,
  isOpen: PropTypes.bool,
};
