// Dictionary based on http://api.dna-dev.tru.io/policyserver/api/Permission
// https://regexr.com/4ne7l

export const Permissions = {
  CanAccessSitesPage: 'CanAccessSitesPage',
  CanReadSites: 'CanReadSites',
  CanAddSites: 'CanAddSites',
  CanEditSites: 'CanEditSites',
  CanRemoveSites: 'CanRemoveSites',
  CanAccessUsersPage: 'CanAccessUsersPage',
  CanReadUsers: 'CanReadUsers',
  CanAddUsers: 'CanAddUsers',
  CanEditUsers: 'CanEditUsers',
  CanRemoveUsers: 'CanRemoveUsers',
  CanCopyUsers: 'CanCopyUsers',
  CanAccessUserRolesPage: 'CanAccessUserRolesPage',
  CanReadUserRoles: 'CanReadUserRoles',
  CanAddUserRoles: 'CanAddUserRoles',
  CanEditUserRoles: 'CanEditUserRoles',
  CanRemoveUserRoles: 'CanRemoveUserRoles',
  CanCopyUserRoles: 'CanCopyUserRoles',
  CanAccessMapAreasPage: 'CanAccessMapAreasPage',
  CanReadMapAreas: 'CanReadMapAreas',
  CanAddMapArea: 'CanAddMapArea',
  CanEditMapArea: 'CanEditMapArea',
  CanRemoveMapArea: 'CanRemoveMapArea',
  CanAccessDevicesPage: 'CanAccessDevicesPage',
  CanReadDevices: 'CanReadDevices',
  CanAddDevices: 'CanAddDevices',
  CanEditDevices: 'CanEditDevices',
  CanRemoveDevices: 'CanRemoveDevices',
  CanAccessLibraryManagementPage: 'CanAccessLibraryManagementPage',
  CanReadLibraries: 'CanReadLibraries',
  CanAddLibraries: 'CanAddLibraries',
  CanEditLibraries: 'CanEditLibraries',
  CanRemoveLibraries: 'CanRemoveLibraries',
  CanCopyLibraries: 'CanCopyLibraries',
  CanAccessDashboardPage: 'CanAccessDashboardPage',
  CanAccessCalendarTemplatesPage: 'CanAccessCalendarTemplatesPage',
  CanReadCalendarTemplates: 'CanReadCalendarTemplates',
  CanAddCalendarTemplates: 'CanAddCalendarTemplates',
  CanEditCalendarTemplates: 'CanEditCalendarTemplates',
  CanRemoveCalendarTemplates: 'CanRemoveCalendarTemplates',
  CanCopyCalendarTemplate: 'CanCopyCalendarTemplate',
  CanAccessHolidaysTemplatesPage: 'CanAccessHolidaysTemplatesPage',
  CanReadHolidaysTemplates: 'CanReadHolidaysTemplates',
  CanAddHolidaysTemplates: 'CanAddHolidaysTemplates',
  CanEditHolidaysTemplates: 'CanEditHolidaysTemplates',
  CanRemoveHolidaysTemplates: 'CanRemoveHolidaysTemplates',
  CanCopyHolidaysTemplate: 'CanCopyHolidaysTemplate',
  CanAccessButtonsLibraryPage: 'CanAccessButtonsLibraryPage',
  CanReadSensitiveButtons: 'CanReadSensitiveButtons',
  CanReadNonSensitiveButtons: 'CanReadNonSensitiveButtons',
  CanAddButtonsLibrary: 'CanAddButtonsLibrary',
  CanEditButtonsLibrary: 'CanEditButtonsLibrary',
  CanRemoveButtonsLibrary: 'CanRemoveButtonsLibrary',
  CanCopyButtonsLibrary: 'CanCopyButtonsLibrary',
  CanAccessAlarmDefinitionsPage: 'CanAccessAlarmDefinitionsPage',
  CanReadAlarms: 'CanReadAlarms',
  CanAddAlarm: 'CanAddAlarm',
  CanEditAlarm: 'CanEditAlarm',
  CanRemoveAlarm: 'CanRemoveAlarm',
  CanReadAlarmNotifications: 'CanReadAlarmNotifications',
  CanAccessAlarmTrackingPage: 'CanAccessAlarmTrackingPage',
  CanAccessAlarmGroupsPage: 'CanAccessAlarmGroupsPage',
  CanAddAlarmGroup: 'CanAddAlarmGroup',
  CanEditAlarmGroup: 'CanEditAlarmGroup',
  CanCopyAlarmGroup: 'CanCopyAlarmGroup',
  CanReadAlarmGroups: 'CanReadAlarmGroups',
  CanRemoveAlarmGroup: 'CanRemoveAlarmGroup',
  CanAddDeviceDrivers: 'CanAddDeviceDrivers',
  CanEditDeviceDrivers: 'CanEditDeviceDrivers',
  CanRemoveDeviceDrivers: 'CanRemoveDeviceDrivers',
  CanCopyDeviceDrivers: 'CanCopyDeviceDrivers',
  CanReadDeviceDrivers: 'CanReadDeviceDrivers',
  CanAccessDevicesGroupsPage: 'CanAccessDevicesGroupsPage',
  CanReadDevicesGroups: 'CanReadDevicesGroups',
  CanAddDevicesGroup: 'CanAddDevicesGroup',
  CanEditDevicesGroup: 'CanEditDevicesGroup',
  CanCopyDevicesGroup: 'CanCopyDevicesGroup',
  CanRemoveDevicesGroup: 'CanRemoveDevicesGroup',
  CanAccessTechnicalArchivesPage: 'CanAccessTechnicalArchivesPage',
  CanReadTechnicalArchives: 'CanReadTechnicalArchives',
  CanAccessSiteGroupsPage: 'CanAccessSiteGroupsPage',
  CanReadSiteGroups: 'CanReadSiteGroups',
  CanAddSiteGroups: 'CanAddSiteGroups',
  CanEditSiteGroups: 'CanEditSiteGroups',
  CanRemoveSiteGroups: 'CanRemoveSiteGroups',
  CanAccessSiteNotificationsPage: 'CanAccessSiteNotificationsPage',
  CanReadSiteNotifications: 'CanReadSiteNotifications',
  CanAddSiteNotifications: 'CanAddSiteNotifications',
  CanEditSiteNotifications: 'CanEditSiteNotifications',
  CanRemoveSiteNotifications: 'CanRemoveSiteNotifications',
  CanCopySiteNotifications: 'CanCopySiteNotifications',
  CanReadSystemNotifications: 'CanReadSystemNotifications',
  CanAccessTechnicalArchiveReportPage: 'CanAccessTechnicalArchiveReportPage',
  CanReadTechnicalArchiveReport: 'CanReadTechnicalArchiveReport',
  CanExportArchiveReportToCsv: 'CanExportArchiveReportToCsv',
  CanExportArchiveReportToPdf: 'CanExportArchiveReportToPdf',
  CanAccessSiteConfigurationPage: 'CanAccessSiteConfigurationPage',
  CanReadSiteConfiguration: 'CanReadSiteConfiguration',
  CanAddSiteConfiguration: 'CanAddSiteConfiguration',
  CanEditSiteConfiguration: 'CanEditSiteConfiguration',
  CanRemoveSiteConfiguration: 'CanRemoveSiteConfiguration',
};
