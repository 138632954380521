import React, { useState, useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { compose } from 'redux';
import { styled } from '@material-ui/core/styles';
import { Permissions } from 'Constants/permissions';
import { FormikEnhanced } from 'Components/formik/formikWrappers';
import { CircularProgress } from '@material-ui/core';
import { copyButtonLibraryFromTemplate } from '../helpers';
import { DevicesConfiguration, DeviceConfigurationDetails } from 'routes/Routes';
import {
  putDevice,
  getDevice,
  getDeviceByName,
  getAffectedAlarmsForDevice,
  getDriverLibrary,
  getLibrariesByDevice,
  postDeviceLibraryAssignFrom,
  putDeviceLibrary,
  postAffectedAlarmsForDevice,
  getButtonLibraryByAssetId,
  putButtonLibrary,
  deleteButtonLibrary,
  getDriverMappingsInfo,
  getDeviceAffectedAlarms,
  putDeviceAssignedAlarms,
} from 'Api/devices';
import Tabs from 'Components/display/tabs/Tabs';
import Button from 'Components/Button';
import SaveOnLeave from 'Components/dialogs/SaveOnLeave';
import { compareObjects } from 'Helpers/ObjectHelper';
import withPermissions from 'hocs/withPermissions';
import General from './tabs/General';
import ImeiHistory from './tabs/ImeiHistory';
import ServiceStatus from './tabs/Service';
import DeviceLocation from './tabs/DeviceLocation';
import OtherConfiguration from './tabs/OtherConfiguration';
import Mappings from './tabs/Mappings';
import Buttons from './tabs/Buttons';
import SaveDialog from './SaveDialog';
import validationSchema from './validationSchema';
import { initializeButtonLibrary } from 'Components/buttonLibrary/reducer/buttonLibraryReducer';
import { addResource, loadResource } from 'app/pages/sites/administration/AlarmDefinitions/helpers';
import { getLibraryInputMappingsSorted } from 'app/pages/sites/administration/libraryManagement/helpers';
import AlarmsAffectedFormLeaveDialog from './AlarmsAffectedFormLeaveDialog';
import { RequestConfigContext, withRequestConfigProvider } from './requestConfigContext';
import { AlarmsAndStatusesDetails } from './tabs/AlarmsAndStatuses';

import { GECKO_KEYWORD_MAPPING_ATTRIBUTE } from 'Constants/GeckoKeywordMappingAttributes';
import { GECKO_ATTRIBUTE_SET_TYPE } from 'Constants/GeckoAttributeSetType';
import { ATTRIBUTE_VALUE_TYPE } from 'Constants/AttributeValueType';

const ButtonStyled = styled(Button)({
  float: 'right',
  marginLeft: '10px',
});

const mapDeviceDto = (data) => {
  return {
    ...data,
    mappings: null,
    deviceButtons: null,
    selectedAlarms: null,
  };
};
const isDeviceDirty = (initialValues, values) => {
  return !compareObjects(mapDeviceDto(initialValues), mapDeviceDto(values));
};
const isAlarmsGroupsDirty = (initialValues, values) => {
  return !compareObjects(mapDeviceDto(initialValues.selectedAlarms), mapDeviceDto(values));
};
const isDeviceLibraryDirty = (initialValues, values) => {
  return !compareObjects(initialValues.mappings, values.mappings);
};
const isDeviceButtonLibraryDirty = (initialValues, values) => {
  return !compareObjects(initialValues.deviceButtons, values.deviceButtons);
};

const Details = (props) => {
  const [device, setDevice] = useState();
  const [deviceLibraries, setDeviceLibraries] = useState();
  const [affectedAlarms, setAffectedAlarms] = useState();
  const [affectedAlarmsDialogOpen, setAffectedAlarmsDialogOpen] = useState(false);
  const [isLoadingAffectedAlarms, setIsLoadingAffectedAlarms] = useState(false);
  const [error, setError] = useState(null);
  const [mappingsInfo, setMappingsInfo] = useState({});
  const requestConfig = useContext(RequestConfigContext);

  const fetchDataCallback = useCallback(
    async (deviceExternalId) => {
      const deviceName = props.match.params.name.replace(/-/g, ' ');

      let deviceResponse;

      try {
        if (!deviceExternalId) {
          deviceResponse = await getDeviceByName(deviceName, requestConfig);
        } else {
          await getDevice(deviceExternalId, requestConfig).then((response) => {
            deviceResponse = response;

            if (deviceName !== response.data.name) {
              const url = DeviceConfigurationDetails.getUrl({
                name: response.data.name.replace(/\s/g, '-'),
              });

              window.history.replaceState(null, null, url);
            }
          });
        }
      } catch (error) {
        if (error.response.status === 404) {
          const url = DevicesConfiguration.getUrl();

          props.history.push(url);

          return;
        }
      }
      const id = deviceResponse.data.id;
      const externalId = deviceResponse.data.externalId;

      Promise.all([
        getLibrariesByDevice(id, requestConfig),
        getButtonLibraryByAssetId(externalId, requestConfig),
        getDeviceAffectedAlarms(externalId, requestConfig),
      ])
        .then(async ([deviceLibrariesResponse, buttonLibraryResponse, affectedAlarmsResponse]) => {
          setDeviceLibraries(deviceLibrariesResponse.data);
          const mappings = deviceLibrariesResponse?.data?.find((library) => library.isCurrentlySelected);
          const deviceButtons = initializeButtonLibrary(buttonLibraryResponse.data);
          const newSelectedAlarms = affectedAlarmsResponse?.data || [];

          const device = {
            ...deviceResponse.data,
            mappings: mappings && getLibraryInputMappingsSorted(mappings),
            deviceButtons,
            selectedAlarms: newSelectedAlarms,
          };

          if (device.imageId && device.imageId !== 0) {
            await loadResource(device, 'image', 'imageId', requestConfig);
          }
          if (mappings) {
            try {
              const { data } = await getDriverMappingsInfo(mappings.driverId, requestConfig);

              setMappingsInfo(data);

              if (data.driverName === 'Gecko') {
                const newKeywordMappings = prepareInitialGeckoKeywordMappings(device.mappings.keywordMappings);

                device.mappings.keywordMappings = newKeywordMappings;
              }
            } catch (error) {
              setError(error);
            }
          }

          setDevice(device);
          setAffectedAlarms(null);
        })
        .catch(() => {
          const url = DevicesConfiguration.getUrl();

          props.history.push(url);
        });
    },
    [props.match, props.history, requestConfig]
  );

  useEffect(() => {
    fetchDataCallback();
  }, [fetchDataCallback]);

  const saveDeviceLibrary = (library) => {
    if (library?.isTemplate) {
      return postDeviceLibraryAssignFrom(
        device.id,
        library.id,
        {
          ...library,
          deviceId: device.id,
          isCurrentlySelected: true,
          isTemplate: false,
        },
        requestConfig
      );
    } else if (library) {
      return putDeviceLibrary(
        device.id,
        library.id,
        {
          ...library,
          isCurrentlySelected: true,
        },
        requestConfig
      );
    } else {
      return putDeviceLibrary(
        device.id,
        library.id,
        {
          ...library,
          isCurrentlySelected: false,
        },
        requestConfig
      );
    }
  };

  const saveAssignedAlarms = async (selectedAlarms) => {
    const alarmIds = selectedAlarms?.map(({ id }) => id);

    await putDeviceAssignedAlarms({ id: device.externalId, alarmIds });
  };

  const saveDeviceButtonLibrary = async (values) => {
    if (values.deviceButtons?.isTemplate) {
      const copyButtonsResponse = await copyButtonLibraryFromTemplate(values.deviceButtons, requestConfig);

      return copyButtonsResponse.data;
    } else if (values.deviceButtons && !values.deviceButtons.isTemplate) {
      await putButtonLibrary(values.deviceButtons.id, values.deviceButtons, requestConfig);

      return values.deviceButtons;
    }

    return null;
  };

  const saveData = async (values) => {
    const requests = [];

    if (isAlarmsGroupsDirty(device, values)) {
      requests.push(saveAssignedAlarms(values.selectedAlarms));
    }

    if (isDeviceLibraryDirty(device, values)) {
      requests.push(saveDeviceLibrary(values.mappings));
    }
    if (isDeviceButtonLibraryDirty(device, values)) {
      const buttonsLibrary = await saveDeviceButtonLibrary(values);

      values.deviceButtons = buttonsLibrary;
      values.buttonLibraryId = buttonsLibrary?.id;
    }
    if (isDeviceDirty(device, values)) {
      if (values.resourceId === 0 && values.icon) {
        await addResource(values, 'icon', 'resourceId', requestConfig);
      }

      if (values.imageId === 0 && values.image) {
        await addResource(values, 'image', 'imageId');
      }
      requests.push(putDevice(values.id, values, requestConfig));
    }

    return Promise.all(requests).catch(() => {
      if (values.buttonLibraryId && device.buttonLibraryId !== values.buttonLibraryId) {
        deleteButtonLibrary(values.buttonLibraryId, requestConfig);
      }
    });
  };

  const fetchDriverMappingsInfo = async (id) => {
    getDriverMappingsInfo(id, requestConfig)
      .then(({ data }) => {
        setMappingsInfo(data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const onMappingsLibraryChange = async (driverId, newLibraryId, handleChange) => {
    setIsLoadingAffectedAlarms(true);
    await Promise.all([
      getAffectedAlarmsForDevice(device.id, newLibraryId, requestConfig),
      getDriverLibrary(driverId, newLibraryId, requestConfig),
    ])
      .then(([{ data: affectedAlarms }, { data: newMappings }]) => {
        if (mappingsInfo.driverName === 'Gecko') {
          newMappings.keywordMappings = prepareInitialGeckoKeywordMappings(newMappings.keywordMappings);
        }

        handleChange({ target: { name: 'mappings', value: getLibraryInputMappingsSorted(newMappings) } });
        setAffectedAlarms(affectedAlarms);
      })
      .catch((error) => {
        setError(error);
      });
    setIsLoadingAffectedAlarms(false);
  };

  const redirect = async () => {
    if (props.location.state) {
      const url = DevicesConfiguration.getUrl({
        siteName: props.location.state.redirectSiteName.replace(/\s/g, '-'),
      });

      props.history.push(url, {
        selectedSiteId: props.location.state.redirectSelectedSiteId,
      });
    } else {
      const url = DevicesConfiguration.getUrl();

      props.history.push(url);
    }
  };

  const onSubmit = async (values, { setSubmitting }) => {
    setIsLoadingAffectedAlarms(true);
    try {
      let newAffectedAlarms = affectedAlarms;

      if (isDeviceLibraryDirty(device, values)) {
        const affectedAlarmsResponse = await postAffectedAlarmsForDevice(
          values.id,
          values.mappings.id,
          values.mappings,
          requestConfig
        );

        newAffectedAlarms = affectedAlarmsResponse.data;
      }

      if (newAffectedAlarms?.length) {
        setAffectedAlarms(newAffectedAlarms);
        setAffectedAlarmsDialogOpen(true);
        setIsLoadingAffectedAlarms(false);
      } else {
        handleConfirmSubmit(values, setSubmitting);
      }
    } catch (error) {
      setAffectedAlarms(null);
      setAffectedAlarmsDialogOpen(true);
      setIsLoadingAffectedAlarms(false);
      setError(error);
    }
  };

  const handleConfirmSubmit = async (values, setSubmitting) => {
    if (values.mappings?.keywordMappings) {
      const newKeywordMappings = prepareKeywordMappingsPayload(values.mappings.keywordMappings);

      values.mappings.keywordMappings = newKeywordMappings;
    }

    setSubmitting(true);
    saveData(values).finally(() => {
      setSubmitting(false);
      redirect();
    });
  };

  const onCancel = () => {
    redirect();
  };

  const saveOnLeave = (values, setSubmitting) => {
    setSubmitting(true);

    return saveData(values).finally(() => {
      setSubmitting(false);
    });
  };

  const onSwitchTabConfirmSave = async (values, setSubmitting) => {
    setSubmitting(true);

    return saveData(values).then(() => {
      fetchDataCallback(device.externalId);
      setSubmitting(false);
    });
  };

  const onSwitchTabContinueWithoutSave = (dirty, resetForm) => {
    if (dirty) {
      resetForm();
      fetchDataCallback(device.externalId);
    }
  };

  const confirmationSubmitHandleClose = (setSubmitting) => {
    setAffectedAlarmsDialogOpen(false);
    setSubmitting(false);
  };

  const checkAffectedAlarms = async (values, isValid) => {
    if (isValid && isDeviceLibraryDirty(device, values)) {
      setIsLoadingAffectedAlarms(true);
      const affectedAlarmsResponse = await postAffectedAlarmsForDevice(
        values.id,
        values.mappings.id,
        values.mappings,
        requestConfig
      );

      setAffectedAlarms(affectedAlarmsResponse.data);
      setIsLoadingAffectedAlarms(false);
    }
  };

  const prepareInitialGeckoKeywordMappings = (library) => {
    const newKeywordMappings = library.map((input) => {
      input.compartmentNumber = input.attributes.find(
        ({ key }) => key === GECKO_KEYWORD_MAPPING_ATTRIBUTE.CompartmentNumber
      )?.value;

      input.compartmentState = input.attributes.find(
        ({ key }) => key === GECKO_KEYWORD_MAPPING_ATTRIBUTE.CompartmentState
      )?.value;

      return input;
    });

    return newKeywordMappings;
  };

  const prepareKeywordMappingsPayload = (keywordMappings) => {
    let keywordMappingsPayload = keywordMappings.length
      ? keywordMappings.map((keywordMappingInput) => ({
          ...keywordMappingInput,
          attributeSetType: GECKO_ATTRIBUTE_SET_TYPE.Default,
          attributes: [],
        }))
      : [];

    if (keywordMappingsPayload.length && mappingsInfo.driverName === 'Gecko') {
      keywordMappingsPayload = keywordMappingsPayload.map(
        ({ name, inputValue, compartmentState, compartmentNumber, isDefault }) => {
          const newAttributes = [];

          const newAttributeSetType =
            compartmentNumber && compartmentState ? GECKO_ATTRIBUTE_SET_TYPE.Gecko : GECKO_ATTRIBUTE_SET_TYPE.Default;

          if (compartmentNumber && compartmentState) {
            newAttributes.push({
              key: GECKO_KEYWORD_MAPPING_ATTRIBUTE.CompartmentState,
              value: compartmentState,
              valueType: ATTRIBUTE_VALUE_TYPE.NUMBER,
            });
            newAttributes.push({
              key: GECKO_KEYWORD_MAPPING_ATTRIBUTE.CompartmentNumber,
              value: compartmentNumber,
              valueType: ATTRIBUTE_VALUE_TYPE.NUMBER,
            });
          }

          return {
            attributeSetType: newAttributeSetType,
            name,
            inputValue,
            isDefault,
            attributes: newAttributes,
          };
        }
      );
    }

    return keywordMappingsPayload;
  };

  if (!device) {
    return <CircularProgress />;
  }

  const canSaveChanges =
    !!props.permissions[Permissions.CanEditDevices] &&
    !!props.permissions[Permissions.CanEditButtonsLibrary] &&
    !!props.permissions[Permissions.CanReadSensitiveButtons] &&
    !!props.permissions[Permissions.CanReadNonSensitiveButtons];

  return (
    <FormikEnhanced
      initialValues={device}
      enableReinitialize
      validationSchema={validationSchema({
        initialValues: device,
        driverName: mappingsInfo.driverName,
        isEditMode: true,
      })}
      validateOnMount
      onSubmit={onSubmit}
      canSaveChanges={canSaveChanges}
    >
      {({ values, isValid, isSubmitting, dirty, handleSubmit, setSubmitting, resetForm, handleChange }) => {
        const disableSaveButton = !(dirty && isValid) || isSubmitting || isLoadingAffectedAlarms || error;

        const formLeaveDialog = {
          element: AlarmsAffectedFormLeaveDialog,
          props: {
            affectedAlarms,
            error,
            isSubmitting: isSubmitting || isLoadingAffectedAlarms,
          },
        };

        return (
          <form onSubmit={handleSubmit}>
            <Tabs
              label="DeviceConfiguration"
              saveChange={() => onSwitchTabConfirmSave(values, setSubmitting)}
              setData={() => onSwitchTabContinueWithoutSave(dirty, resetForm)}
              onTryChangeTab={() => checkAffectedAlarms(values, isValid)}
              showConfirmationDialog={dirty && canSaveChanges}
              validForm={isValid}
              customDialog={formLeaveDialog}
            >
              <General label={LocaleKeys.labels.general} />
              <ImeiHistory label={LocaleKeys.labels.imeiHistory} />
              <ServiceStatus label={LocaleKeys.labels.service} />
              <DeviceLocation label={LocaleKeys.labels.deviceLocation} />
              <OtherConfiguration label={LocaleKeys.labels.otherConfiguration} />
              <AlarmsAndStatusesDetails
                label={LocaleKeys.labels.alarmsGroups}
                deviceExternalId={device.externalId}
                mappingsInfo={mappingsInfo}
                values={values}
                handleSubmit={handleSubmit}
                handleChange={handleChange}
              />
              <Mappings
                label={LocaleKeys.labels.mappings}
                deviceLibraries={deviceLibraries}
                affectedAlarms={affectedAlarms}
                isLoadingAffectedAlarms={isLoadingAffectedAlarms}
                onMappingsLibraryChange={onMappingsLibraryChange}
                mappingsInfo={mappingsInfo}
                fetchDriverMappingsInfo={fetchDriverMappingsInfo}
              />
              <Buttons label={LocaleKeys.labels.libraryButtons} />
            </Tabs>

            <ButtonStyled onClick={onCancel} id="devicesConfiguration-calncelButton">
              {T.translate(LocaleKeys.labels.cancel)}
            </ButtonStyled>
            {canSaveChanges && (
              <>
                <ButtonStyled
                  key={'SubmitButton'}
                  disabled={disableSaveButton}
                  type="submit"
                  id="devicesConfiguration-submitButton"
                  showProgress={isSubmitting || isLoadingAffectedAlarms}
                >
                  {T.translate(LocaleKeys.labels.save)}
                </ButtonStyled>

                <SaveOnLeave
                  saveData={() => saveOnLeave(values, setSubmitting)}
                  dataChanged={dirty || !!props.location?.state?.redirectSiteId}
                  validForm={isValid}
                  onTryChangeLocation={() => checkAffectedAlarms(values)}
                  customDialog={formLeaveDialog}
                />
                <SaveDialog
                  open={affectedAlarmsDialogOpen}
                  isSubmitting={isSubmitting}
                  error={error}
                  affectedAlarms={affectedAlarms}
                  handleSubmit={() => handleConfirmSubmit(values, setSubmitting)}
                  handleCancel={() => confirmationSubmitHandleClose(setSubmitting)}
                  handleReload={fetchDataCallback}
                />
              </>
            )}
          </form>
        );
      }}
    </FormikEnhanced>
  );
};

Details.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  permissions: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.shape({
      redirectSiteId: PropTypes.string,
      redirectSiteName: PropTypes.string,
      redirectSelectedSiteId: PropTypes.string,
    }),
  }),
};

export default compose(
  withPermissions([
    Permissions.CanAccessDevicesPage,
    Permissions.CanReadDevices,
    Permissions.CanReadNonSensitiveButtons,
    Permissions.CanReadSensitiveButtons,
    Permissions.CanAddButtonsLibrary,
    Permissions.CanReadLibraries,
    Permissions.CanReadDeviceDrivers,
  ]),
  withRequestConfigProvider
)(Details);
