const MessagesLocaleKeys = {
  accountLoginProivdeCredential: 'accountLoginProivdeCredential',
  accountForgotPasswordEnterEmail: 'accountForgotPasswordEnterEmail',
  accountProvideNewPassword: 'accountProvideNewPassword',
  copiedSuccessfullyDialogMessage: 'copiedSuccessfullyDialogMessage',
  defaultConfirmDialogMessage: 'defaultConfirmDialogMessage',
  defaultConfirmDialogTitle: 'defaultConfirmDialogTitle',
  deleteItemDialogTitle: 'deleteItemDialogTitle',
  deleteItemGroupDialogTitle: 'deleteItemGroupDialogTitle',
  deleteItemGroupDialogMessage: 'deleteItemGroupDialogMessage',
  deleteItemDialogMessage: 'deleteItemDialogMessage',
  deleteDeviceDialogTitle: 'deleteDeviceDialogTitle',
  deleteDeviceDialogMessage: 'deleteDeviceDialogMessage',
  deleteDriverDialogMessage: 'deleteDriverDialogMessage',
  deleteDriverDialogTitle: 'deleteDriverTitle',
  deleteSiteGroupDialogMessage: 'deleteSiteGroupDialogMessage',
  deleteSiteNotificationDialogMessage: 'deleteSiteNotificationDialogMessage',
  deleteSiteGroupDialogTitle: 'deleteSiteGroupDialogTitle',
  deleteSiteNotificationDialogTitle: 'deleteSiteNotificationDialogTitle',
  deleteAlarmGroupDialogTitle: 'deleteAlarmGroupDialogTitle',
  deleteAlarmGroupDialogMessage: 'deleteAlarmGroupDialogMessage',
  deleteSiteDialogMessage: 'deleteSiteDialogMessage',
  deleteSiteDialogTitle: 'deleteSiteDialogTitle',
  deleteUserDialogMessage: 'deleteUserDialogMessage',
  deleteUserDialogTitle: 'deleteUserDialogTitle',
  deleteWhiteIpAddressDialogTitle: 'deleteWhiteIpAddressDialogTitle',
  deleteWhiteIpAddressDialogMessage: 'deleteWhiteIpAddressDialogMessage',
  deleteMapAreaDialogMessage: 'deleteMapAreaDialogMessage',
  deleteMapAreaDialogTitle: 'deleteMapAreaDialogTitle',
  loginProcess: 'loginProcess',
  youDontHavePermission: 'youDontHavePermission',
  youDontHaveAccessToSelectedSite: 'youDontHaveAccessToSelectedSite',
  errorDuringLoginProcess: 'errorDuringLoginProcess',
  errorWhileProcessingRequest: 'errorWhileProcessingRequest',
  errorWhileRefreshSession: 'errorWhileRefreshSession',
  requestTookTooLongToComplete: 'requestTookTooLongToComplete',
  notificationsOutOfSync: 'notificationsOutOfSync',
  signalRTryingToReconnect: 'signalRTryingToReconnect',
  signarRReconnected: 'signarRReconnected',
  currentlySelectedStatusesAndAlarms: 'currentlySelectedStatusesAndAlarms',
  selectOneOfGroup: 'selectOneOfGroup',
  errorDuringLoadData: 'errorDuringLoadData',
  deleteCalendarDialogMessage: 'deleteCalendarDialogMessage',
  deleteCalendarDialogTitle: 'deleteCalendarDialogTitle',
  deleteHolidayDialogMessage: 'deleteHolidayDialogMessage',
  deleteHolidayDialogTitle: 'deleteHolidayDialogTitle',
  userDoesNotHaveDefaultSite: 'userDoesNotHaveDefaultSite',
  outdatedLibraryMappingsWarning: 'outdatedLibraryMappingsWarning',
  dontHavePermissionToReadUsers: 'dontHavePermissionToReadUsers',
  dontHavePermissionToReadSites: 'dontHavePermissionToReadSites',
  dontHavePermissionToModifyIpWhiteList: 'dontHavePermissionToModifyIpWhiteList',
  dontHavePermissionToReadUserRoles: 'dontHavePermissionToReadUserRoles',
  dontHavePermissionToReadMapAreas: 'dontHavePermissionToReadMapAreas',
  dontHavePermissionToReadDevicesConfiguration: 'dontHavePermissionToReadDevicesConfiguration',
  dontHavePermissionToReadButtonsLibrary: 'dontHavePermissionToReadButtonsLibrary',
  requiredPermission: 'requiredPermission',
  brokenAlarmsDetected: 'brokenAlarmsDetected',
  brokenAlarmsDialogMessage: 'brokenAlarmsDialogMessage',
  brokenAlarmsDialogTitle: 'brokenAlarmsDialogTitle',
  belowAlarmsWillBeAffectedByThisChange: 'belowAlarmsWillBeAffectedByThisChange',
  belowCalendarsWillBeAffectedByThisChange: 'belowCalendarsWillBeAffectedByThisChange',
  onLeaveValidFormMessage: 'onLeaveValidFormMessage',
  onLeaveInvalidFormMessage: 'onLeaveInvalidFormMessage',
  maximumImageSizeExceeded: 'maximumImageSizeExceeded',
  noMapProvidersAssignedToSiteInfo: 'noMapProvidersAssignedToSiteInfo',
  exceededSelectedDevicesLimitForMappingsPreview: 'exceededSelectedDevicesLimitForMappingsPreview',
  assignedAssetsAreRequiredToAddFormula: 'assignedAssetsAreRequiredToAddFormula',
  sessionExpiringDialogTitle: 'sessionExpiringDialogTitle',
  sessionExpiringDialogMessage: 'sessionExpiringDialogMessage',
  sessionExpiredDialogMessage: 'sessionExpiredDialogMessage',
  sessionExpiringRefreshSessionButton: 'sessionExpiringRefreshSessionButton',
  sessionExpiringLogoutButton: 'sessionExpiringLogoutButton',
  alarmReasonTypeSingleValue: 'alarmReasonTypeSingleValue',
  alarmReasonTypeAreaInside: 'alarmReasonTypeAreaInside',
  alarmReasonTypeAreaOutside: 'alarmReasonTypeAreaOutside',
  alarmReasonTypeRangeValue: 'alarmReasonTypeRangeValue',
  alarmReasonTypeMethod: 'alarmReasonTypeMethod',
  alarmTitle: 'alarmTitle',
  errorWhileFetchingNotifications: 'errorWhileFetchingNotifications',
  errorWhileFetchingData: 'errorWhileFetchingData',
  noAddressForTheGivenGeolocation: 'noAddressForTheGivenGeolocation',
  pleaseReloadAndTryAgain: 'pleaseReloadAndTryAgain',
  pleaseReload: 'pleaseReload',
  clickRefreshToTryAgain: 'clickRefreshToTryAgain',
  clickRefreshToReload: 'clickRefreshToReload',
  noInputsMappingsForSelectedDriver: 'noInputsMappingsForSelectedDriver',
  noDeviceLibrarySelected: 'noDeviceLibrarySelected',
  affectedAlarmsRequireUserAction: 'affectedAlarmsRequireUserAction',
  deleteUserRoleDialogMessage: 'deleteUserRoleDialogMessage',
  deleteUserRoleDialogTitle: 'deleteUserRoleDialogTitle',
  deleteButtonsLibraryDialogMessage: 'deleteButtonsLibraryDialogMessage',
  deleteButtonsLibraryDialogTitle: 'deleteButtonsLibraryDialogTitle',
  deleteAlarmDialogTitle: 'deleteAlarmDialogTitle',
  deleteAlarmDialogMessage: 'deleteAlarmDialogMessage',
  deleteDevicesGroupDialogMessage: 'deleteDevicesGroupDialogMessage',
  deleteDevicesGroupDialogTitle: 'deleteDevicesGroupDialogTitle',
  deleteSiteConfigurationDialogMessage: 'deleteSiteConfigurationDialogMessage',
  deleteSiteConfigurationDialogTitle: 'deleteSiteConfigurationDialogTitle',
  deactivateMapAreaDialogTitle: 'deactivateMapAreaDialogTitle',
  mappingsChange: 'mappingsChange',
  notSupportedFileXtension: 'notSupportedFileXtension',
  thisFileExtensionIsNotSupported: 'thisFileExtensionIsNotSupported',
  youAreLimitedByAssignedDeviceGroups: 'youAreLimitedByAssignedDeviceGroups',
  areYouSureToReadThoseMessages: 'areYouSureToReadThoseMessages',
  welcomeToTheVector: 'welcomeToTheVector',
  overHereYouWillSeeThreatBoardItems: 'overHereYouWillSeeThreatBoardItems',
  yourPasswordIsSoonToExpireIn: 'yourPasswordIsSoonToExpireIn',
  accountActivationEmailSentForUser: 'accountActivationEmailSentForUser',
  accountActivationAwaitingForUser: 'accountActivationAwaitingForUser',
  accountActivatedForUser: 'accountActivatedForUser',
  pleaseChangeItOtherwiseYouWillNotBeAbleToLogIn: 'pleaseChangeItOtherwiseYouWillNotBeAbleToLogIn',
  passwordHasBeenResetForUser: 'passwordHasBeenResetForUser',
  noNotifications: 'noNotifications',
  accountHasNotBeenActivatedForUser: 'accountHasNotBeenActivatedForUser',
  messagesWillBeReadUpToSetLimitForOperation: 'messagesWillBeReadUpToSetLimitForOperation',
  newVersionAvailablePleaseCloseAllTabsAndReload: 'newVersionAvailablePleaseCloseAllTabsAndReload',
};

export default MessagesLocaleKeys;
