import { useState, useEffect } from 'react';
import { getValue, putValueCreateOrUpdate } from 'Api/configurationStore';

export const useSelectedAddressesList = ({ configurationStoreName }) => {
  const ADDRESSES_LIST_KEY = `${configurationStoreName}-addresses`;
  const [rowsWithVisibleAddressIds, setRowsWithVisibleAddressIds] = useState([]);

  const handleSelectedAddressesListChange = (newRowsWithVisibleAddressesIds) => {
    setRowsWithVisibleAddressIds(newRowsWithVisibleAddressesIds);
    if (JSON.stringify(rowsWithVisibleAddressIds) !== JSON.stringify(newRowsWithVisibleAddressesIds)) {
      putValueCreateOrUpdate(ADDRESSES_LIST_KEY, { value: JSON.stringify(newRowsWithVisibleAddressesIds) });
    }
  };

  useEffect(() => {
    getValue(ADDRESSES_LIST_KEY).then(({ data, status }) => {
      if (status === 200) {
        setRowsWithVisibleAddressIds(data);
      }
    });
  }, []);

  return { rowsWithVisibleAddressIds, handleSelectedAddressesListChange };
};
