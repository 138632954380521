const RoutesLocaleKeys = {
  sites: 'sites',
  users: 'users',
  userRoles: 'userRoles',
  mapAreas: 'mapAreas',
  devicesConfiguration: 'devicesConfiguration',
  libraryManagement: 'libraryManagement',
  alarmGroups: 'alarmGroups',
  deviceDrivers: 'deviceDrivers',
  dashboard: 'dashboard',
  calendarTemplates: 'calendarTemplates',
  holidayTemplates: 'holidayTemplates',
  buttonsLibrary: 'buttonsLibrary',
  alarmDefinitions: 'alarmDefinitions',
  alarmTracking: 'alarmTracking',
  devicesGroups: 'devicesGroups',
  technicalArchives: 'technicalArchives',
  siteGroups: 'siteGroups',
  siteNotifications: 'siteNotifications',
  technicalArchiveReport: 'technicalArchiveReport',
  siteConfiguration: 'siteConfiguration',
};

export default RoutesLocaleKeys;
