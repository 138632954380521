export const AppRoutes = {
  CALLBACK: 'callback',
  SILENT_REFRESH_CALLBACK: 'silentRefreshCallback',
  ROOT: '',
  EDIT: 'edit',
  NEW: 'new',
  DASHBOARD: 'dashboard',
  ACCOUNT: 'account',
  FORGOT_PASSWORD: 'forgot-password',
  NEW_PASSWORD: 'new-password',
  HOME: 'home',
  SITES: 'sites',
  SITES_GROUPS: 'site-groups',
  SITE_NOTIFICATIONS: 'site-notifications',
  USERS: 'users',
  USER_ROLES: 'user-roles',
  DEVICES_CONFIGURATION: 'devices-configuration',
  MAP_AREAS: 'map-areas',
  ALARMS_DEFINITIONS: 'alarms-definitions',
  ALARM_TRACKING: 'alarm-tracking',
  ALARM_GROUPS: 'alarms-groups',
  CALENDAR_TEMPLATES: 'calendar-templates',
  HOLIDAY_TEMPLATES: 'holiday-templates',
  LIBRARY_MANAGEMENT: 'library-management',
  BUTTONS_LIBRARY: 'buttons-library',
  TECHNICAL_ARCHIVE: 'technical-archive',
  DEVICES_GROUPS: 'devices-groups',
  ARCHIVE: 'archive',
  SITE_CONFIGURATION: 'site-configuration',
};

const en = Object.fromEntries(Object.values(AppRoutes).map((path) => [path, path]));

const de = {
  [AppRoutes.CALLBACK]: 'callback',
  [AppRoutes.SILENT_REFRESH_CALLBACK]: 'silentRefreshCallback',
  [AppRoutes.ROOT]: '',
  [AppRoutes.NEW]: 'neu',
  [AppRoutes.EDIT]: 'bearbeiten',
  [AppRoutes.DASHBOARD]: 'dashboard',
  [AppRoutes.ACCOUNT]: 'konto',
  [AppRoutes.FORGOT_PASSWORD]: 'passwort-vergessen',
  [AppRoutes.NEW_PASSWORD]: 'neues-passwort',
  [AppRoutes.HOME]: 'home',
  [AppRoutes.SITES]: 'sites',
  [AppRoutes.SITES_GROUPS]: 'site-gruppen',
  [AppRoutes.SITE_NOTIFICATIONS]: 'site-benachrichtigungen',
  [AppRoutes.USERS]: 'user',
  [AppRoutes.USER_ROLES]: 'userrollen',
  [AppRoutes.DEVICES_CONFIGURATION]: 'gerätekonfiguration',
  [AppRoutes.MAP_AREAS]: 'kartenbereiche',
  [AppRoutes.ALARMS_DEFINITIONS]: 'alarmdeffinitionen',
  [AppRoutes.ALARM_TRACKING]: 'alarmtracking',
  [AppRoutes.CALENDAR_TEMPLATES]: 'kalendervorlagen',
  [AppRoutes.HOLIDAY_TEMPLATES]: 'urlaubstemplates',
  [AppRoutes.LIBRARY_MANAGEMENT]: 'librarymanagement',
  [AppRoutes.ALARM_GROUPS]: 'alarmgruppen',
  [AppRoutes.BUTTONS_LIBRARY]: 'buttons-library',
  [AppRoutes.TECHNICAL_ARCHIVE]: 'technisches-archiv',
  [AppRoutes.DEVICES_GROUPS]: 'gerätegruppen',
  [AppRoutes.ARCHIVE]: 'archiv',
  [AppRoutes.SITE_CONFIGURATION]: 'site-konfiguration',
};

const routePaths = {
  en,
  de,
};

const getKeyByValue = (object, value) => Object.keys(object).find((key) => object[key] === value);

export const translatePathSegment = (path, translateTo, translateFrom = 'en') => {
  const key = getKeyByValue(routePaths[translateFrom], path);

  if (key && translateTo) {
    return routePaths[translateTo][key];
  }

  return path;
};

export const translatePath = (pathname, language, prevLanguage = 'en') => {
  const segments = pathname.split('/');
  const siteName = segments[1];
  const routeSegments = segments.slice(2);
  const translatedRouteSegments = routeSegments.map((segment, index) => {
    if (index === 0 || (routeSegments.length > 1 && index < routeSegments.length - 1)) {
      return translatePathSegment(segment, language, prevLanguage);
    }

    return segment;
  });

  return ['', siteName, ...translatedRouteSegments].join('/');
};
