import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { compose } from 'redux';
import { CircularProgress, Box, styled, Paper, Divider } from '@material-ui/core';
import { FormikEnhanced } from 'Components/formik/formikWrappers';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import Button from 'Components/Button';
import { getAlarmGroupByName, getAlarms, putAlarmGroup, postAlarmGroup } from 'Api/devices';
import { AlarmGroups } from 'routes/Routes';
import SaveOnLeave from 'Components/dialogs/SaveOnLeave';
import { clone } from 'Helpers/ObjectHelper';
import ListInput from 'Components/inputs/ListInput';
import AlarmsGroupValidators from './AlarmsGroupValidators';
import { TextField } from 'Components/formik/fields';

const initialState = {
  name: '',
  description: '',
  alarms: [],
};

const Container = styled(Paper)({
  height: 'calc(100vh - 170px)',
  overflow: 'hidden',
  margin: 10,
});

const ActionButton = styled(Button)({
  float: 'right',
  marginLeft: '10px',
});

const TopSection = styled(Box)({
  margin: 10,
  gap: 16,
  display: 'flex',
  minWidth: 450,
});

const Details = (props) => {
  const [alarmsGroup, setAlarmsGroup] = useState(null);
  const [alarms, setAlarms] = useState(null);

  const isEditMode = !!props.match.params.name;

  const onRedirect = () => {
    const url = AlarmGroups.getUrl();

    props.history.push(url);
  };

  const onSubmit = (values, { setSubmitting }) => {
    const body = { ...values, description: values.description || null }; // db storage optimization

    if (isEditMode) {
      putAlarmGroup(values.externalId, body).then(() => {
        setSubmitting(false);
        onRedirect();
      });
    } else {
      postAlarmGroup(body).then(() => {
        setSubmitting(false);
        onRedirect();
      });
    }
  };

  useEffect(() => {
    getAlarms(1, 10000, 'id', {}).then(({ data }) => {
      setAlarms(data.data);
    });
  }, []);

  useEffect(() => {
    const name = props.match.params?.name?.replace(/-/g, ' ');
    const locationState = props.history.location.state;

    if (name || locationState?.basedOn) {
      getAlarmGroupByName(name || locationState.basedOn.replace(/-/g, ' ')).then(({ data }) => {
        if (!name) {
          setAlarmsGroup({
            ...clone(initialState),
            alarms: data.alarms,
          });
        } else {
          setAlarmsGroup(data);
        }
      });
    } else {
      setAlarmsGroup(clone(initialState));
    }
  }, [props.history.location.state, props.match.params]);

  if (!alarmsGroup && !alarms) {
    return <CircularProgress />;
  }

  const canSaveChanges = isEditMode
    ? !!props.permissions[Permissions.CanEditAlarmGroup]
    : !!props.permissions[Permissions.CanAddAlarmGroup];

  return (
    <FormikEnhanced
      initialValues={alarmsGroup}
      enableReinitialize
      validationSchema={AlarmsGroupValidators}
      validateOnSubmit
      onSubmit={onSubmit}
      canSaveChanges={canSaveChanges}
    >
      {({ values, dirty, isValid, handleSubmit, handleChange }) => (
        <form onSubmit={handleSubmit}>
          <Container>
            <TopSection>
              <TextField label={T.translate(LocaleKeys.labels.groupName)} name="name" showPending />
              <TextField
                label={T.translate(LocaleKeys.labels.description)}
                name="description"
                style={{ minWidth: 240 }}
              />
            </TopSection>

            <Divider />
            <Box height={'calc(100% - 120px)'}>
              <ListInput
                selected={values?.alarms}
                items={alarms}
                disabled={!canSaveChanges}
                onChange={(value) => handleChange({ target: { value, name: 'alarms' } })}
                valueField="externalId"
                displayField="name"
                label={T.translate(LocaleKeys.labels.alarmsStatuses)}
                width={'450px'}
              />
            </Box>
          </Container>
          <Box m={2}>
            <ActionButton onClick={onRedirect} id="alarmsGroups-cancelButton">
              {T.translate(LocaleKeys.labels.cancel)}
            </ActionButton>
            {canSaveChanges && (
              <ActionButton type="submit" disabled={!isValid || !dirty} id="alarmsGroups-submitButton">
                {T.translate(LocaleKeys.labels.save)}
              </ActionButton>
            )}
          </Box>
          {canSaveChanges && <SaveOnLeave saveData={onSubmit} dataChanged={dirty} validForm={isValid} />}
        </form>
      )}
    </FormikEnhanced>
  );
};

Details.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
};

export const AlarmGroupsNew = compose(
  withRouter,
  withPermissions([Permissions.CanAccessAlarmGroupsPage, Permissions.CanAddAlarmGroup, Permissions.CanReadAlarmGroups])
)(Details);

export const AlarmGroupsDetails = compose(
  withRouter,
  withPermissions([Permissions.CanAccessAlarmGroupsPage, Permissions.CanReadAlarmGroups])
)(Details);
