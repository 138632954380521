import React from 'react';
import Layout from 'app/layout/Layout';
import ErrorBoundary from 'Components/ErrorBoundary';
import { Switch } from 'react-router-dom';
import * as Routes from '../../routes/Routes';

import PermissionRoute from '../../auth/PermissionRoute';
import CalendarTemplates from '../pages/sites/administration/calendarTemplates/CalendarTemplates';
import DeviceConfigurationDetails from '../pages/sites/devices/devicesConfiguration/details/Details';
import DevicesConfiguration from '../pages/sites/devices/devicesConfiguration/DevicesConfiguration';
import Dashboard from '../pages/Dashboard';
import LibraryManagement from '../pages/sites/administration/libraryManagement/LibraryManagement';
import AlarmGroups from '../pages/sites/administration/alarmGroups/AlarmGroups';
import { AlarmGroupsNew, AlarmGroupsDetails } from '../pages/sites/administration/alarmGroups/Details';
import LibraryDetails from '../pages/sites/administration/libraryManagement/details/Details';
import MapAreas from '../pages/sites/tracking/mapAreas/MapAreas';
import MapDetails from './../pages/sites/tracking/mapAreas/MapDetails';
import NewDeviceConfiguration from '../pages/sites/devices/devicesConfiguration/details/New';
import NewLibrary from '../pages/sites/administration/libraryManagement/details/New';
import NewMapArea from '../pages/sites/tracking/mapAreas/New';
import NewSite from '../pages/sites/administration/sites/details/New';
import NewSiteNotification from '../pages/sites/administration/siteNotifications/details/New';
import NewUser from '../pages/sites/administration/users/details/New';
import SiteNotification from '../pages/sites/administration/siteNotifications/SiteNotifications';
import SiteNotificationDetail from '../pages/sites/administration/siteNotifications/details/Details';
import Sites from '../pages/sites/administration/sites/Sites';
import SitesDetails from '../pages/sites/administration/sites/details/Details';
import Users from '../pages/sites/administration/users/Users';
import UsersDetails from '../pages/sites/administration/users/details/Details';
import HolidayTemplates from '../pages/sites/administration/holidays/HolidaysTemplates';
import HolidayDetails, { HolidayNew } from '../pages/sites/administration/holidays/HolidayDetails';
import UserRoles from 'app/pages/sites/administration/userRoles/UserRoles';
import UserRolesDetails from 'app/pages/sites/administration/userRoles/details/Details';
import NewUserRoles from 'app/pages/sites/administration/userRoles/details/New';
import StatusAndAlarmDefinitions from '../pages/sites/administration/AlarmDefinitions/StatusAndAlarmDefinitions';
import NewAlarmDefinition from '../pages/sites/administration/AlarmDefinitions/New';
import AlarmDefinitionsDetails from '../pages/sites/administration/AlarmDefinitions/Details';
import ButtonsLibrary from '../pages/sites/administration/buttonsLibrary/ButtonsLibrary';
import AlarmTracking from '../pages/sites/tracking/alarmTracking';
import { Permissions } from 'Constants/permissions';
import HomePage from 'app/pages/sites/HomePage';
import CalendarTemplateDetails from '../pages/sites/administration/calendarTemplates/details/Details';
import CalendarTemplateNew from '../pages/sites/administration/calendarTemplates/details/New';
import TechnicalArchive from '../pages/sites/administration/technicalArchive/TechnicalArchive';
import DevicesGroups from 'app/pages/sites/devices/DevicesGroups';
import Archive from '../pages/sites/reports/archive/index';
import { DevicesGroupsNew, DevicesGroupsDetails } from 'app/pages/sites/devices/DevicesGroups/Details';
import SitesGroups from 'app/pages/sites/administration/siteGroups';
import { SitesGroupsNew, SitesGroupsDetails } from 'app/pages/sites/administration/siteGroups/Details';
import SiteConfiguration from 'app/pages/sites/customization/SiteConfiguration/index';
import SiteConfigurationDetails from 'app/pages/sites/customization/SiteConfiguration/Details/Edit';
import SiteConfigurationNew from 'app/pages/sites/customization/SiteConfiguration/Details/New';

class ContentRouter extends React.Component {
  render() {
    return (
      <Layout>
        <ErrorBoundary>
          <Switch>
            {/* Home */}
            <PermissionRoute path={Routes.Root.getFullPath()} component={HomePage} exact />

            {/* Dashboard */}
            <PermissionRoute
              path={Routes.Dashboard.getFullPath()}
              component={Dashboard}
              exact
              permission={Permissions.CanAccessDashboardPage}
            />

            {/* Users */}
            <PermissionRoute
              path={Routes.Users.getFullPath()}
              exact
              component={Users}
              permission={Permissions.CanAccessUsersPage}
            />

            <PermissionRoute
              path={Routes.NewUser.getFullPath()}
              exact
              component={NewUser}
              permission={Permissions.CanAccessUsersPage}
            />

            <PermissionRoute
              path={Routes.UsersDetails.getFullPath()}
              exact
              component={UsersDetails}
              permission={Permissions.CanAccessUsersPage}
            />

            {/* UserRoles */}
            <PermissionRoute
              path={Routes.UserRoles.getFullPath()}
              exact
              component={UserRoles}
              permission={Permissions.CanAccessUserRolesPage}
            />

            <PermissionRoute
              path={Routes.NewUserRoles.getFullPath()}
              exact
              component={NewUserRoles}
              permission={Permissions.CanAccessUserRolesPage}
            />

            <PermissionRoute
              path={Routes.UserRolesDetails.getFullPath()}
              exact
              component={UserRolesDetails}
              permission={Permissions.CanAccessUserRolesPage}
            />

            {/* Devices */}
            <PermissionRoute
              path={Routes.DevicesConfiguration.getFullPath()}
              exact
              component={DevicesConfiguration}
              permission={Permissions.CanAccessDevicesPage}
            />
            <PermissionRoute
              path={Routes.NewDeviceConfiguration.getFullPath()}
              component={NewDeviceConfiguration}
              permission={Permissions.CanAccessDevicesPage}
            />
            <PermissionRoute
              path={Routes.DeviceConfigurationDetails.getFullPath()}
              component={DeviceConfigurationDetails}
              permission={Permissions.CanAccessDevicesPage}
            />

            {/* Sites */}
            <PermissionRoute
              path={Routes.Sites.getFullPath()}
              exact
              component={Sites}
              permission={Permissions.CanAccessSitesPage}
            />
            <PermissionRoute
              path={Routes.NewSite.getFullPath()}
              component={NewSite}
              permission={Permissions.CanAccessSitesPage}
            />
            <PermissionRoute
              path={Routes.SiteDetails.getFullPath()}
              exact
              component={SitesDetails}
              permission={Permissions.CanAccessSitesPage}
            />

            {/* Site Groups */}
            <PermissionRoute
              path={Routes.SitesGroups.getFullPath()}
              exact
              component={SitesGroups}
              permission={Permissions.CanAccessSiteGroupsPage}
            />
            <PermissionRoute
              path={Routes.SitesGroupsNew.getFullPath()}
              component={SitesGroupsNew}
              permission={Permissions.CanAccessSiteGroupsPage}
            />
            <PermissionRoute
              path={Routes.SitesGroupsDetails.getFullPath()}
              component={SitesGroupsDetails}
              permission={Permissions.CanAccessSiteGroupsPage}
            />

            {/* Site Notification */}
            <PermissionRoute
              path={Routes.SiteNotifications.getFullPath()}
              exact
              component={SiteNotification}
              permission={Permissions.CanAccessSiteNotificationsPage}
            />
            <PermissionRoute
              path={Routes.NewSiteNotification.getFullPath()}
              component={NewSiteNotification}
              permission={Permissions.CanAccessSiteNotificationsPage}
            />
            <PermissionRoute
              path={Routes.SiteNotificationDetails.getFullPath()}
              component={SiteNotificationDetail}
              permission={Permissions.CanAccessSiteNotificationsPage}
            />

            {/* Map */}
            <PermissionRoute
              path={Routes.MapAreas.getFullPath()}
              exact
              component={MapAreas}
              permission={Permissions.CanAccessMapAreasPage}
            />

            <PermissionRoute
              path={Routes.NewMapArea.getFullPath()}
              component={NewMapArea}
              permission={Permissions.CanAccessMapAreasPage}
            />

            <PermissionRoute
              path={Routes.MapDetails.getFullPath()}
              component={MapDetails}
              permission={Permissions.CanAccessMapAreasPage}
            />

            {/* Status and Alarm Definitions */}
            <PermissionRoute
              path={Routes.StatusAndAlarmDefinitions.getFullPath()}
              exact
              component={StatusAndAlarmDefinitions}
              permission={Permissions.CanAccessAlarmDefinitionsPage}
            />

            <PermissionRoute
              path={Routes.NewStatusAndAlarmDefinitions.getFullPath()}
              component={NewAlarmDefinition}
              permission={Permissions.CanAccessAlarmDefinitionsPage}
            />

            <PermissionRoute
              path={Routes.StatusAndAlarmDefinitionsDetails.getFullPath()}
              component={AlarmDefinitionsDetails}
              permission={Permissions.CanAccessAlarmDefinitionsPage}
            />

            {/* Calendar Templates */}
            <PermissionRoute
              path={Routes.CalendarTemplates.getFullPath()}
              exact
              component={CalendarTemplates}
              permission={Permissions.CanAccessCalendarTemplatesPage}
            />
            <PermissionRoute
              path={Routes.CalendarNew.getFullPath()}
              component={CalendarTemplateNew}
              permission={Permissions.CanAccessCalendarTemplatesPage}
            />
            <PermissionRoute
              path={Routes.CalendarDetails.getFullPath()}
              component={CalendarTemplateDetails}
              permission={Permissions.CanAccessCalendarTemplatesPage}
            />

            {/* Holiday Templates */}
            <PermissionRoute
              path={Routes.HolidayTemplates.getFullPath()}
              exact
              component={HolidayTemplates}
              permission={Permissions.CanAccessHolidaysTemplatesPage}
            />
            <PermissionRoute
              path={Routes.HolidayNew.getFullPath()}
              exact
              component={HolidayNew}
              permission={Permissions.CanAccessHolidaysTemplatesPage}
            />
            <PermissionRoute
              path={Routes.HolidayDetails.getFullPath()}
              exact
              component={HolidayDetails}
              permission={Permissions.CanAccessHolidaysTemplatesPage}
            />

            {/* Library Management */}
            <PermissionRoute
              path={Routes.LibraryManagement.getFullPath()}
              exact
              component={LibraryManagement}
              permission={Permissions.CanAccessLibraryManagementPage}
            />
            <PermissionRoute
              path={Routes.LibraryNew.getFullPath()}
              component={NewLibrary}
              permission={Permissions.CanAccessLibraryManagementPage}
            />
            <PermissionRoute
              path={Routes.LibraryDetails.getFullPath()}
              component={LibraryDetails}
              permission={Permissions.CanAccessLibraryManagementPage}
            />

            {/* Alarm Groups */}
            <PermissionRoute
              path={Routes.AlarmGroups.getFullPath()}
              exact
              component={AlarmGroups}
              permission={Permissions.CanAccessAlarmGroupsPage}
            />

            <PermissionRoute
              path={Routes.AlarmGroupsNew.getFullPath()}
              component={AlarmGroupsNew}
              permission={Permissions.CanAccessAlarmGroupsPage}
            />
            <PermissionRoute
              path={Routes.AlarmGroupsDetails.getFullPath()}
              component={AlarmGroupsDetails}
              permission={Permissions.CanAccessAlarmGroupsPage}
            />

            {/* Buttons Library */}
            <PermissionRoute
              path={Routes.ButtonsLibrary.getFullPath()}
              exact
              component={ButtonsLibrary}
              permission={Permissions.CanAccessButtonsLibraryPage}
            />
            <PermissionRoute
              path={Routes.ButtonsLibraryDetails.getFullPath()}
              exact
              component={ButtonsLibrary}
              permission={Permissions.CanAccessButtonsLibraryPage}
            />
            <PermissionRoute
              path={Routes.ButtonsLibraryNew.getFullPath()}
              exact
              component={ButtonsLibrary}
              permission={Permissions.CanAccessButtonsLibraryPage}
            />
            {/* Alarm Tracking */}
            <PermissionRoute
              path={Routes.AlarmTracking.getFullPath()}
              exact
              component={AlarmTracking}
              permission={Permissions.CanAccessAlarmTrackingPage}
            />

            {/* Technical Archive */}
            <PermissionRoute
              path={Routes.TechnicalArchive.getFullPath()}
              exact
              component={TechnicalArchive}
              permission={Permissions.CanAccessTechnicalArchivesPage}
            />

            {/* Devices Groups */}
            <PermissionRoute
              path={Routes.DevicesGroups.getFullPath()}
              exact
              component={DevicesGroups}
              permission={Permissions.CanAccessDevicesGroupsPage}
            />
            <PermissionRoute
              path={Routes.DevicesGroupsNew.getFullPath()}
              component={DevicesGroupsNew}
              permission={Permissions.CanAccessDevicesGroupsPage}
            />
            <PermissionRoute
              path={Routes.DevicesGroupsDetails.getFullPath()}
              component={DevicesGroupsDetails}
              permission={Permissions.CanAccessDevicesGroupsPage}
            />

            {/* Reports */}
            <PermissionRoute
              component={Archive}
              exact
              path={Routes.Archive.getFullPath()}
              permission={Permissions.CanAccessTechnicalArchiveReportPage}
            />

            {/* Customization */}
            <PermissionRoute
              component={SiteConfiguration}
              exact
              path={Routes.SiteConfiguration.getFullPath()}
              permission={Permissions.CanAccessSiteConfigurationPage}
            />
            <PermissionRoute
              component={SiteConfigurationDetails}
              exact
              path={Routes.SiteConfigurationDetails.getFullPath()}
              permission={Permissions.CanAccessSiteConfigurationPage}
            />
            <PermissionRoute
              component={SiteConfigurationNew}
              path={Routes.SiteConfigurationNew.getFullPath()}
              permission={Permissions.CanAccessSiteConfigurationPage}
            />
          </Switch>
        </ErrorBoundary>
      </Layout>
    );
  }
}

export default ContentRouter;
