import React from 'react';
import PropTypes from 'prop-types';
import ExpansionSection from 'Components/display/ExpansionPanel/ExpansionSection';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import DeviceDriver from './mappings/DeviceDriver';
import DigitalInput from './mappings/DigitalInput';
import AnalogInput from './mappings/AnalogInput';
import EventKeywords from './mappings/EventKeywords';
import GeckoEventKeywords from './mappings/GeckoEventKeywords';
import { withFormik } from 'Components/formik/formikWrappers';

const Mappings = ({ data, mappingsInfo, fetchDriverMappingsInfo, ...props }) => {
  const digitalInOutEnabled = mappingsInfo.digitalInputsEnabled || mappingsInfo.digitalOutputsEnabled;
  const showDigitalMappings = data.mappings?.digitalInputMappings?.length || digitalInOutEnabled;
  const showAnalogMappings = data.mappings?.analogInputMappings?.length || mappingsInfo.analogInputsEnabled;
  const showKeywordMappings = data.mappings?.keywordMappings?.length || mappingsInfo.keywordsEnabled;

  return (
    <ExpansionSection data={data}>
      <DeviceDriver
        label={T.translate(LocaleKeys.labels.deviceDriver)}
        deviceLibraries={props.deviceLibraries}
        brokenAlarms={props.affectedAlarms}
        isLoadingBrokenAlarms={props.isLoadingAffectedAlarms}
        onMappingsLibraryChange={props.onMappingsLibraryChange}
        fetchDriverMappingsInfo={fetchDriverMappingsInfo}
      />
      {showDigitalMappings && <DigitalInput label={LocaleKeys.labels.digitalInputMapping} />}
      {showAnalogMappings && <AnalogInput label={LocaleKeys.labels.analogInputMapping} />}
      {showKeywordMappings ? (
        mappingsInfo.driverName === 'Gecko' ? (
          <GeckoEventKeywords label={LocaleKeys.labels.eventKeywordsMapping} />
        ) : (
          <EventKeywords label={LocaleKeys.labels.eventKeywordsMapping} />
        )
      ) : null}
    </ExpansionSection>
  );
};

Mappings.propTypes = {
  data: PropTypes.object,
  deviceLibraries: PropTypes.array,
  affectedAlarms: PropTypes.array,
  isLoadingAffectedAlarms: PropTypes.bool,
  onMappingsLibraryChange: PropTypes.func,
  mappingsInfo: PropTypes.objectOf({
    analogInputsEnabled: PropTypes.bool,
    digitalInputsEnabled: PropTypes.bool,
    digitalOutputsEnabled: PropTypes.bool,
    keywordsEnabled: PropTypes.bool,
  }),
};

Mappings.defaultProps = {
  deviceLibraries: [],
  affectedAlarms: [],
  brokenAlarmsLoading: false,
};

export default withFormik(Mappings);
