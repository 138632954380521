const TooltipsLocaleKeys = {
  deleteSite: 'deleteSite',
  editSite: 'editSite',
  addNewItem: 'addNewItem',
  deleteItem: 'deleteItem',
  editItem: 'editItem',
  copyItem: 'copyItem',
  copyUser: 'copyUser',
  editMapArea: 'editMapArea',
  deleteMapArea: 'deleteMapArea',
  copyHolidayTemplate: 'copyHolidayTemplate',
  assignItem: 'assignItem',
  thisSiteNotificationCannotBeEdited: 'thisSiteNotificationCannotBeEdited',
  deviceWithAlarmsCannotBeMovedToOtherSite: 'deviceWithAlarmsCannotBeMovedToOtherSite',
  disableTracking: 'disableTracking',
  enableTracking: 'enableTracking',
  disableLabels: 'disableLabels',
  enableLabels: 'enableLabels',
  deselectAll: 'deselectAll',
  inputCannotBeUnsigned: 'inputCannotBeUnsigned',
  inputMismatched: 'inputMismatched',
  alarmDeactivatedForcefully: 'alarmDeactivatedForcefully',
  error: 'error',
};

export default TooltipsLocaleKeys;
