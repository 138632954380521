import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { LibraryManagement } from 'routes/Routes';
import Button from 'Components/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import LibraryDefinition from './tabs/LibraryDefinition';
import LocaleKeys from 'Localization/LocaleKeys';
import SaveOnLeave from 'Components/dialogs/SaveOnLeave';
import T from 'i18n-react';
import { styled } from '@material-ui/core/styles';
import { removeProperty, clone } from 'Helpers/ObjectHelper';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import {
  getDigitalSources,
  getAnalogSources,
  getMetricVariables,
  getEventKeywordVariables,
  getDrivers,
  getDriverLibrary,
  postDriverLibrary,
  getDriverMappings,
  getDriverMappingsInfo,
} from 'Api/devices';
import { Formik } from 'formik';
import Tabs from 'Components/display/tabs/Tabs';
import validationSchema from './validationSchema';
import { getLibraryInputMappingsSorted } from '../helpers';

import { ATTRIBUTE_VALUE_TYPE } from 'Constants/AttributeValueType';
import { GECKO_KEYWORD_MAPPING_ATTRIBUTE } from 'Constants/GeckoKeywordMappingAttributes';
import { GECKO_ATTRIBUTE_SET_TYPE } from 'Constants/GeckoAttributeSetType';

const ButtonStyled = styled(Button)({
  float: 'right',
  marginLeft: '10px',
});

const getTemplateFromLibrary = (library) => {
  const newLibrary = clone(library);

  newLibrary.name = '';
  newLibrary.deviceid = null;
  newLibrary.isTemplate = true;
  newLibrary.isCurrentlySelected = false;
  removeProperty(newLibrary, 'externalId');
  removeProperty(newLibrary, 'id');

  return newLibrary;
};

class New extends React.Component {
  state = {
    dataChanged: false,
    isLoaded: false,
    library: {
      name: '',
      analogInputMappings: [],
      digitalInputMappings: [],
      keywordMappings: [],
    },
    drivers: [],
    dictionaries: {
      analogSources: [],
      digitalSources: [],
      eventKeywordsRelatedVariables: [],
      metricRelatedVariables: [],
      analogInputs: [],
      digitalInputs: [],
      digitalOutputs: [],
    },
    mappingsInfo: {},
    isLoadingMappings: false,
  };

  componentDidMount() {
    if (this.props.history.location.state) {
      const { id, driverId } = this.props.history.location.state;

      if (id && driverId) {
        Promise.all([getDriverMappings(driverId), getDriverMappingsInfo(driverId), getDriverLibrary(driverId, id)])
          .then(([{ data: driverMappings }, { data: mappingsInfo }, { data: newLibrary }]) => {
            if (mappingsInfo.driverName === 'Gecko') {
              newLibrary.keywordMappings = this.prepareInitialGeckoKeywordMappings(newLibrary);
            }

            this.setState(
              (prevState) => ({
                ...prevState,
                dictionaries: {
                  ...prevState.dictionaries,
                  analogInputs: driverMappings.analogInputs,
                  digitalInputs: driverMappings.digitalInputs,
                  digitalOutputs: driverMappings.digitalOutputs,
                },
                library: getLibraryInputMappingsSorted(getTemplateFromLibrary(newLibrary)),
                mappingsInfo,
                isLoadingMappings: false,
              }),
              this.fetchData()
            );
          })
          .catch(() => {
            this.redirect();
          });
      }
    } else {
      this.fetchData();
    }
  }

  prepareInitialGeckoKeywordMappings = (library) => {
    const newKeywordMappings = library.keywordMappings.map((input) => {
      input.compartmentNumber = input.attributes.find(
        ({ key }) => key === GECKO_KEYWORD_MAPPING_ATTRIBUTE.CompartmentNumber
      )?.value;

      input.compartmentState = input.attributes.find(
        ({ key }) => key === GECKO_KEYWORD_MAPPING_ATTRIBUTE.CompartmentState
      )?.value;

      return input;
    });

    return newKeywordMappings;
  };

  fetchData = () => {
    Promise.all([
      getDigitalSources(),
      getAnalogSources(),
      getMetricVariables(),
      getEventKeywordVariables(),
      getDrivers(),
    ]).then(
      ([
        digitalSourceResponse,
        analogSourceResponse,
        metricRelatedResponse,
        eventKeywordsRelatedResponse,
        driversResponse,
      ]) => {
        this.setState((prevState) => ({
          ...prevState,
          dictionaries: {
            ...prevState.dictionaries,
            digitalSources: digitalSourceResponse.data,
            analogSources: analogSourceResponse.data,
            metricRelatedVariables: metricRelatedResponse.data,
            eventKeywordsRelatedVariables: eventKeywordsRelatedResponse.data,
          },
          isLoaded: true,
          drivers: driversResponse.data.map((el) => ({
            id: el.id,
            name: el.name,
          })),
        }));
      }
    );
  };

  prepareKeywordMappingsPayload = (keywordMappings) => {
    let keywordMappingsPayload = keywordMappings.length
      ? keywordMappings.map((keywordMappingInput) => ({
          ...keywordMappingInput,
          attributeSetType: GECKO_ATTRIBUTE_SET_TYPE.Default,
          attributes: [],
        }))
      : [];

    if (keywordMappingsPayload.length && this.state.mappingsInfo.driverName === 'Gecko') {
      keywordMappingsPayload = keywordMappingsPayload.map(
        ({ name, inputValue, compartmentState, compartmentNumber, isDefault }) => {
          const newAttributes = [];

          const newAttributeSetType =
            compartmentNumber && compartmentState ? GECKO_ATTRIBUTE_SET_TYPE.Gecko : GECKO_ATTRIBUTE_SET_TYPE.Default;

          if (compartmentNumber && compartmentState) {
            newAttributes.push({
              key: GECKO_KEYWORD_MAPPING_ATTRIBUTE.CompartmentState,
              value: compartmentState,
              valueType: ATTRIBUTE_VALUE_TYPE.NUMBER,
            });
            newAttributes.push({
              key: GECKO_KEYWORD_MAPPING_ATTRIBUTE.CompartmentNumber,
              value: compartmentNumber,
              valueType: ATTRIBUTE_VALUE_TYPE.NUMBER,
            });
          }

          return {
            attributeSetType: newAttributeSetType,
            name,
            inputValue,
            isDefault,
            attributes: newAttributes,
          };
        }
      );
    }

    return keywordMappingsPayload;
  };

  saveData = (values) => {
    const library = values;

    const newKeywordMappings = this.prepareKeywordMappingsPayload(library.keywordMappings);

    library.keywordMappings = newKeywordMappings;
    library.isTemplate = true;

    // Temporary fix for required backend validation on removed source field
    library.analogInputMappings = library.analogInputMappings.map((input) => ({ ...input, source: 1 }));

    return postDriverLibrary(library.driverId, library);
  };

  redirect = () => {
    const url = LibraryManagement.getUrl();

    this.props.history.push(url);
  };

  onSubmit = (values, { setSubmitting }) => {
    this.saveData(values).finally(() => {
      setSubmitting(false);
      this.redirect();
    });
  };

  onCancel = () => {
    this.redirect();
  };

  loadDriverMappings = (driverId) => {
    this.setState({ isLoadingMappings: true });
    Promise.all([getDriverMappings(driverId), getDriverMappingsInfo(driverId)]).then(
      ([{ data: driverMappings }, { data: mappingsInfo }]) => {
        this.setState((prevState) => ({
          ...prevState,
          dictionaries: {
            ...prevState.dictionaries,
            analogInputs: driverMappings.analogInputs,
            digitalInputs: driverMappings.digitalInputs,
            digitalOutputs: driverMappings.digitalOutputs,
          },
          mappingsInfo,
          isLoadingMappings: false,
        }));
      }
    );
  };

  onDriverChange = (event, setFieldValue) => {
    const { value } = event.target;

    this.loadDriverMappings(value);
    setFieldValue('analogInputMappings', [], false);
    setFieldValue('digitalInputMappings', [], false);
    setFieldValue('keywordMappings', [], false);
  };

  render() {
    const { isLoaded, drivers, library, mappingsInfo, isLoadingMappings } = this.state;
    const noEnoughDrivers = !drivers || drivers.length === 0;

    if (!isLoaded) {
      return <CircularProgress />;
    }
    if (noEnoughDrivers) {
      return T.translate(LocaleKeys.labels.pleaseAddDriverBeforeAddingNewLibrary);
    }

    return (
      <Formik
        initialValues={library}
        enableReinitialize
        validationSchema={validationSchema(mappingsInfo)}
        validateOnMount
        onSubmit={this.onSubmit}
      >
        {({ values, isValid, isSubmitting, dirty, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Tabs label="libraryDetails" defaultTab={0}>
              <LibraryDefinition
                label={LocaleKeys.labels.libraryDefinition}
                dictionaries={this.state.dictionaries}
                onDriverChange={(event) => this.onDriverChange(event, setFieldValue)}
                mappingsInfo={mappingsInfo}
              />
            </Tabs>

            <ButtonStyled onClick={this.onCancel} id="new-libraryManagement-cancelButton">
              {T.translate(LocaleKeys.labels.cancel)}
            </ButtonStyled>
            <ButtonStyled
              disabled={!(dirty && isValid) || isSubmitting || isLoadingMappings}
              type="submit"
              id="new-libraryManagement-submitButton"
              showProgress={isSubmitting}
            >
              {T.translate(LocaleKeys.labels.save)}
            </ButtonStyled>

            <SaveOnLeave saveData={() => this.saveData(values)} dataChanged={dirty} validForm={isValid} />
          </form>
        )}
      </Formik>
    );
  }
}

New.propTypes = {
  history: PropTypes.object,
  permissions: PropTypes.object,
};

export default compose(
  withPermissions([
    Permissions.CanAccessLibraryManagementPage,
    Permissions.CanReadDeviceDrivers,
    Permissions.CanReadLibraries,
    Permissions.CanAddLibraries,
  ])
)(New);
