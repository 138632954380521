import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import withPermissions from 'hocs/withPermissions';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Permissions } from 'Constants/permissions';
import { AlarmGroupsDetails, AlarmGroupsNew } from 'routes/Routes';
import { getAlarmGroups } from 'Api/devices';

import Table from 'Components/display/table/Table';
import { styled, CircularProgress } from '@material-ui/core';

import { useDeleteDialogActions } from './useDeleteDialogActions';
import { DeleteAlarmGroupDialog } from './DeleteAlarmGroupDialog';
import { getTableActionButtons } from './getTableActionButtons';

const TableStyled = styled(Table)({
  padding: 20,
});

const formatDataForTableView = (groups) =>
  Object.values(groups).map((group) => ({
    alarmGroupName: group.name,
    description: group.description,
    alarmCount: group.alarms?.length || 0,
    id: group.externalId,
    alarms: group.alarms || [],
  }));

export const AlarmGroups = (props) => {
  const [alarmGroups, setAlarmGroups] = useState(null);
  const { dialogOpen, onOpen, itemToHandle, onCancel, onConfirm } = useDeleteDialogActions({ setAlarmGroups });

  const onEditClick = (name) => {
    const url = AlarmGroupsDetails.getUrl({ name: name.replace(/\s/g, '-') });

    props.history.push(url);
  };

  const onAddClick = () => {
    const url = AlarmGroupsNew.getUrl();

    props.history.push(url);
  };

  const onCopyClick = ({ name }) => {
    const url = AlarmGroupsNew.getUrl();

    props.history.push(url, { basedOn: name });
  };

  useEffect(() => {
    getAlarmGroups().then(({ data }) => {
      setAlarmGroups(data.alarmGroups);
    });
  }, []);

  if (!alarmGroups) {
    return <CircularProgress />;
  }

  const formattedTableData = formatDataForTableView(alarmGroups || []);
  const actionButtons = getTableActionButtons({
    onEditClick,
    onCopyClick,
    onDeleteClick: onOpen,
    permissions: props.permissions,
  });

  return (
    <>
      <TableStyled
        data={formattedTableData}
        onRowDoubleClick={(row) => onEditClick(row.alarmGroupName)}
        onAddClick={props.permissions[Permissions.CanAddAlarmGroup] && onAddClick}
        redirect={'name'}
        hideColumns={['id', 'alarms']}
        actionButtons={actionButtons}
        tableName="alarmGroups"
      />

      <DeleteAlarmGroupDialog
        isOpen={dialogOpen}
        onConfirm={onConfirm}
        onCancel={onCancel}
        disableConfirm={!itemToHandle}
        itemToHandle={itemToHandle}
      />
    </>
  );
};

AlarmGroups.propTypes = {
  history: PropTypes.object,
  permissions: PropTypes.object,
};

export default compose(withRouter, withPermissions([Permissions.CanAccessAlarmGroupsPage]))(AlarmGroups);
