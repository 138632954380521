import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Navigation from './Navigation';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { getMenuByLogin } from 'Api/sites';
import { compose } from 'redux';

import PermissionManager from 'Auth/PermissionManager';
import { Permissions } from 'Constants/permissions';

// icons
import alarmDefinitionIcon from './assets/alarm-definition.svg';
import alarmTrackingIcon from './assets/alarm-tracking.svg';
import archiveIcon from './assets/archive.svg';
import buttonsLibraryIcon from './assets/buttons-library.svg';
import calendarTemplateIcon from './assets/calendar-template.svg';
import deviceConfigurationIcon from './assets/device-configuration.svg';
import deviceGroupsIcon from './assets/devices-groups.svg';
import holidayTemplateIcon from './assets/holiday-template.svg';
import libraryManagementIcon from './assets/library-management.svg';
import mapAreasIcon from './assets/map-areas.svg';
import sitesGroupsIcon from './assets/sites-group.svg';
import sitesNotificationIcon from './assets/sites-notification.svg';
import sitesIcon from './assets/sites.svg';
import technicalArchiveIcon from './assets/technical-archive.svg';
import usersRolesIcon from './assets/users-roles.svg';
import usersIcon from './assets/users.svg';
import administrationIcon from './assets/administration.svg';
import trackingIcon from './assets/tracking.svg';
import devicesIcon from './assets/devices.svg';
import reportsIcon from './assets/reports.svg';
import customizationIcon from './assets/customization.svg';
import defaultIcon from './assets/default.svg';
import siteConfigurationIcon from './assets/site-configuration.svg';
import { LocalStorageKeys } from 'Constants/LocalStorageKeys';
import { AppRoutes, translatePathSegment } from 'routes/paths';

const getIcon = (icon) => {
  switch (icon) {
    case 'administration':
      return administrationIcon;
    case 'tracking':
      return trackingIcon;
    case 'devices':
      return devicesIcon;
    case 'reports':
      return reportsIcon;
    case 'customization':
      return customizationIcon;
    default:
      return defaultIcon;
  }
};

const translatePage = (path, language) => {
  return path && translatePathSegment(path.substr(1), language);
};

const generateLink = (node, currentSite, language) => {
  const link = {
    label: node.title,
    link: `/${currentSite.replace(/\s/g, '-')}/${translatePage(node.page, language)}`,
  };

  switch (node.page) {
    case `/${AppRoutes.USERS}`:
      link.icon = usersIcon;
      link.disabled = !PermissionManager.hasPermissionFromCache(Permissions.CanAccessUsersPage);
      break;
    case `/${AppRoutes.BUTTONS_LIBRARY}`:
      link.icon = buttonsLibraryIcon;
      link.disabled = !PermissionManager.hasPermissionFromCache(Permissions.CanAccessButtonsLibraryPage);
      break;
    case `/${AppRoutes.USER_ROLES}`:
      link.icon = usersRolesIcon;
      link.disabled = !PermissionManager.hasPermissionFromCache(Permissions.CanAccessUserRolesPage);
      break;
    case `/${AppRoutes.ALARM_GROUPS}`:
      link.icon = libraryManagementIcon;
      link.disabled = !PermissionManager.hasPermissionFromCache(Permissions.CanAccessAlarmGroupsPage);
      break;
    case `/${AppRoutes.LIBRARY_MANAGEMENT}`:
      link.icon = libraryManagementIcon;
      link.disabled = !PermissionManager.hasPermissionFromCache(Permissions.CanAccessLibraryManagementPage);
      break;
    case `/${AppRoutes.SITES_GROUPS}`:
      link.icon = sitesGroupsIcon;
      link.disabled = !PermissionManager.hasPermissionFromCache(Permissions.CanAccessSiteGroupsPage);
      break;
    case `/${AppRoutes.SITE_NOTIFICATIONS}`:
      link.icon = sitesNotificationIcon;
      link.disabled = !PermissionManager.hasPermissionFromCache(Permissions.CanAccessSiteNotificationsPage);
      break;
    case `/${AppRoutes.CALENDAR_TEMPLATES}`:
      link.icon = calendarTemplateIcon;
      link.disabled = !PermissionManager.hasPermissionFromCache(Permissions.CanAccessCalendarTemplatesPage);
      break;
    case `/${AppRoutes.HOLIDAY_TEMPLATES}`:
      link.icon = holidayTemplateIcon;
      link.disabled = !PermissionManager.hasPermissionFromCache(Permissions.CanAccessHolidaysTemplatesPage);
      break;
    case `/${AppRoutes.TECHNICAL_ARCHIVE}`:
      link.icon = technicalArchiveIcon;
      link.disabled = !PermissionManager.hasPermissionFromCache(Permissions.CanAccessTechnicalArchivesPage);
      break;
    case `/${AppRoutes.ALARMS_DEFINITIONS}`:
      link.icon = alarmDefinitionIcon;
      link.disabled = !PermissionManager.hasPermissionFromCache(Permissions.CanAccessAlarmDefinitionsPage);
      break;
    case `/${AppRoutes.SITES}`:
      link.icon = sitesIcon;
      link.disabled = !PermissionManager.hasPermissionFromCache(Permissions.CanAccessSitesPage);
      break;
    case `/${AppRoutes.SITE_CONFIGURATION}`:
      link.icon = siteConfigurationIcon;
      link.disabled = !PermissionManager.hasPermissionFromCache(Permissions.CanAccessSiteConfigurationPage);
      break;
    case `/${AppRoutes.ARCHIVE}`:
      link.icon = archiveIcon;
      link.disabled = !PermissionManager.hasPermissionFromCache(Permissions.CanAccessTechnicalArchiveReportPage);
      break;
    case `/${AppRoutes.DEVICES_GROUPS}`:
      link.icon = deviceGroupsIcon;
      link.disabled = !PermissionManager.hasPermissionFromCache(Permissions.CanAccessDevicesGroupsPage);
      break;
    case `/${AppRoutes.DEVICES_CONFIGURATION}`:
      link.icon = deviceConfigurationIcon;
      link.disabled = !PermissionManager.hasPermissionFromCache(Permissions.CanAccessDevicesPage);
      break;
    case `/${AppRoutes.ALARM_TRACKING}`:
      link.icon = alarmTrackingIcon;
      link.disabled = !PermissionManager.hasPermissionFromCache(Permissions.CanAccessAlarmTrackingPage);
      break;
    case `/${AppRoutes.MAP_AREAS}`:
      link.icon = mapAreasIcon;
      link.disabled = !PermissionManager.hasPermissionFromCache(Permissions.CanAccessMapAreasPage);
      break;
    default:
      link.icon = archiveIcon;
      break;
  }

  return link;
};

const Layout = ({ classes, children, currentSite }) => {
  const [userMenu, setUserMenu] = useState(null);

  useEffect(() => {
    const fetchUserMenu = async () => {
      const response = await getMenuByLogin();
      const userLanguage = localStorage.getItem(LocalStorageKeys.USER_LANGUAGE);

      if (response.data) {
        setUserMenu(
          JSON.parse(response.data.items[userLanguage]).map((mainEntry) => ({
            label: mainEntry.title,
            link: `/${currentSite.replace(/\s/g, '-')}/${translatePage(mainEntry.page, userLanguage)}`,
            icon: getIcon(mainEntry?.defaultIcon),
            options: mainEntry?.children?.map((subEntry) => generateLink(subEntry, currentSite, userLanguage)) || [],
          }))
        );
      }
    };

    fetchUserMenu();
  }, [currentSite]);

  if (!userMenu) {
    return null;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Navigation options={userMenu} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.element,
};

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
});

const mapState = (state) => ({
  // just to rererender with correct urls
  currentSite: state.sites.currentSite,
});

export default compose(withStyles(styles, { withTheme: true }), connect(mapState))(Layout);
