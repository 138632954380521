import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { LibraryManagement, LibraryDetails } from 'routes/Routes';
import Button from 'Components/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import LibraryDefinition from './tabs/LibraryDefinition';
import LocaleKeys from 'Localization/LocaleKeys';
import SaveOnLeave from 'Components/dialogs/SaveOnLeave';
import T from 'i18n-react';
import { styled } from '@material-ui/core/styles';
import { Permissions } from 'Constants/permissions';
import withPermissions from 'hocs/withPermissions';
import {
  getDriverLibrary,
  getDigitalSources,
  getAnalogSources,
  getMetricVariables,
  getEventKeywordVariables,
  putDriverLibrary,
  getDriverMappings,
  getLibraryByName,
  getLibrary,
  getDriverMappingsInfo,
} from 'Api/devices';
import Tabs from 'Components/display/tabs/Tabs';
import validationSchema from './validationSchema';
import { getLibraryInputMappingsSorted } from '../helpers';
import { FormikEnhanced } from 'Components/formik/formikWrappers';

import { GECKO_KEYWORD_MAPPING_ATTRIBUTE } from 'Constants/GeckoKeywordMappingAttributes';
import { GECKO_ATTRIBUTE_SET_TYPE } from 'Constants/GeckoAttributeSetType';
import { ATTRIBUTE_VALUE_TYPE } from 'Constants/AttributeValueType';

const ButtonStyled = styled(Button)({
  float: 'right',
  marginLeft: '10px',
});

class Details extends React.Component {
  state = {
    dataChanged: false,
    isLoaded: false,
    library: {},
    drivers: [],
    dictionaries: {
      analogSources: [],
      digitalSources: [],
      eventKeywordsRelatedVariables: [],
      metricRelatedVariables: [],
      analogInputs: [],
      digitalInputs: [],
      digitalOutputs: [],
    },
    mappingsInfo: {},
  };

  componentDidMount() {
    this.fetchData(true);
  }

  fetchData = async (byName) => {
    const { name } = this.props.match.params;

    let response;

    if (byName) {
      response = await getLibraryByName(name.replace(/-/g, ' '));
    } else {
      await getLibrary(this.state.id).then((r) => {
        response = r;

        if (name !== response.data.name) {
          const url = LibraryDetails.getUrl({
            name: this.state.data.name.replace(/\s/g, '-'),
          });

          window.history.replaceState(null, null, url);
        }
      });
    }
    const { id, driverId } = response.data;

    Promise.all([
      getDriverLibrary(driverId, id),
      getDriverMappings(driverId),
      getDriverMappingsInfo(driverId),
      getDigitalSources(),
      getAnalogSources(),
      getMetricVariables(),
      getEventKeywordVariables(),
    ]).then(
      ([
        libraryResponse,
        driversMappingsResponse,
        { data: mappingsInfo },
        digitalSourceResponse,
        analogSourceResponse,
        metricRelatedResponse,
        eventKeywordsRelatedResponse,
      ]) => {
        const library = libraryResponse.data;

        if (mappingsInfo.driverName === 'Gecko') {
          library.keywordMappings = this.prepareInitialGeckoKeywordMappings(library);
        }

        this.setState({
          library: getLibraryInputMappingsSorted(library),
          dictionaries: {
            digitalSources: digitalSourceResponse.data,
            analogSources: analogSourceResponse.data,
            metricRelatedVariables: metricRelatedResponse.data,
            eventKeywordsRelatedVariables: eventKeywordsRelatedResponse.data,
            analogInputs: driversMappingsResponse.data.analogInputs,
            digitalInputs: driversMappingsResponse.data.digitalInputs,
            digitalOutputs: driversMappingsResponse.data.digitalOutputs,
          },
          mappingsInfo,
          libraryId: id,
          isLoaded: true,
        });
      }
    );
  };

  prepareInitialGeckoKeywordMappings = (library) => {
    const newKeywordMappings = library.keywordMappings.map((input) => {
      input.compartmentNumber = input.attributes.find(
        ({ key }) => key === GECKO_KEYWORD_MAPPING_ATTRIBUTE.CompartmentNumber
      )?.value;

      input.compartmentState = input.attributes.find(
        ({ key }) => key === GECKO_KEYWORD_MAPPING_ATTRIBUTE.CompartmentState
      )?.value;

      return input;
    });

    return newKeywordMappings;
  };

  prepareKeywordMappingsPayload = (keywordMappings) => {
    let keywordMappingsPayload = keywordMappings.length
      ? keywordMappings.map((keywordMappingInput) => ({
          ...keywordMappingInput,
          attributeSetType: GECKO_ATTRIBUTE_SET_TYPE.Default,
          attributes: [],
        }))
      : [];

    if (keywordMappingsPayload.length && this.state.mappingsInfo.driverName === 'Gecko') {
      keywordMappingsPayload = keywordMappingsPayload.map(
        ({ name, inputValue, compartmentState, compartmentNumber, isDefault }) => {
          const newAttributes = [];

          const newAttributeSetType =
            compartmentNumber && compartmentState ? GECKO_ATTRIBUTE_SET_TYPE.Gecko : GECKO_ATTRIBUTE_SET_TYPE.Default;

          if (compartmentNumber && compartmentState) {
            newAttributes.push({
              key: GECKO_KEYWORD_MAPPING_ATTRIBUTE.CompartmentState,
              value: compartmentState,
              valueType: ATTRIBUTE_VALUE_TYPE.NUMBER,
            });
            newAttributes.push({
              key: GECKO_KEYWORD_MAPPING_ATTRIBUTE.CompartmentNumber,
              value: compartmentNumber,
              valueType: ATTRIBUTE_VALUE_TYPE.NUMBER,
            });
          }

          return {
            attributeSetType: newAttributeSetType,
            name,
            inputValue,
            isDefault,
            attributes: newAttributes,
          };
        }
      );
    }

    return keywordMappingsPayload;
  };

  saveData = (library) => {
    const newKeywordMappings = this.prepareKeywordMappingsPayload(library.keywordMappings);

    library.keywordMappings = newKeywordMappings;
    // Temporary fix for required backend validation on removed source field
    library.analogInputMappings = library.analogInputMappings.map((input) => ({ ...input, source: 1 }));

    return putDriverLibrary(library.driverId, library.id, library).then(() => {
      this.setState((prevState) => ({
        ...prevState,
        library,
      }));
    });
  };

  redirect = () => {
    const url = LibraryManagement.getUrl();

    this.props.history.push(url);
  };

  onSubmit = (values, { setSubmitting }) => {
    this.saveData(values).finally(() => {
      setSubmitting(false);
      this.redirect();
    });
  };

  onCancel = () => {
    this.redirect();
  };

  onSwitchTabConfirmSave = (values, setSubmitting) => {
    setSubmitting(true);
    this.saveData(values).then(() => {
      this.fetchData();
      setSubmitting(false);
    });
  };

  onSwitchTabContinueWithoutSave = (dirty) => {
    if (dirty) {
      this.fetchData();
    }
  };

  render() {
    const canSaveChanges = !!this.props.permissions[Permissions.CanEditLibraries];

    if (!this.state.isLoaded) {
      return <CircularProgress />;
    }

    return (
      <FormikEnhanced
        initialValues={this.state.library}
        enableReinitialize
        validationSchema={validationSchema(this.state.mappingsInfo)}
        validateOnMount
        onSubmit={this.onSubmit}
        canSaveChanges={canSaveChanges}
      >
        {({ values, isValid, isSubmitting, dirty, handleSubmit, setSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Tabs
              label="libraryDetails"
              saveChange={() => this.onSwitchTabConfirmSave(values, setSubmitting)}
              setData={() => this.onSwitchTabContinueWithoutSave(dirty)}
              showConfirmationDialog={dirty && canSaveChanges}
              validForm={isValid}
            >
              <LibraryDefinition
                label={LocaleKeys.labels.libraryDefinition}
                dictionaries={this.state.dictionaries}
                mappingsInfo={this.state.mappingsInfo}
              />
            </Tabs>

            <ButtonStyled onClick={this.onCancel} id="libraryManagement-cancelButton">
              {T.translate(LocaleKeys.labels.cancel)}
            </ButtonStyled>
            {canSaveChanges && [
              <ButtonStyled
                key={'SubmitButton'}
                disabled={!(dirty && isValid) || isSubmitting}
                type="submit"
                id="libraryManagement-submitButton"
                showProgress={isSubmitting}
              >
                {T.translate(LocaleKeys.labels.save)}
              </ButtonStyled>,

              <SaveOnLeave
                key={'SaveOnLeave'}
                saveData={() => this.saveData(values)}
                dataChanged={dirty}
                validForm={isValid}
              />,
            ]}
          </form>
        )}
      </FormikEnhanced>
    );
  }
}

Details.propTypes = {
  history: PropTypes.object,
  permissions: PropTypes.object,
};

export default compose(
  withPermissions([
    Permissions.CanAccessLibraryManagementPage,
    Permissions.CanReadDeviceDrivers,
    Permissions.CanReadLibraries,
  ])
)(Details);
