import React from 'react';

import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import AdjustIcon from '@material-ui/icons/Adjust';
import { Box, styled, Paper, Typography, List, ListItem, Divider } from '@material-ui/core';

const SelectedItemsContainer = styled(Paper)({
  width: '450px',
  height: 'calc(100% - 24px)',
  overflow: 'hidden',
  margin: 10,
  padding: 12,
});

const SectionTitle = styled(Typography)({
  marginBottom: 12,
});

const StyledListItem = styled(ListItem)(() => ({
  cursor: 'default',
}));

const DotPlaceholder = styled(AdjustIcon)({
  width: 16,
  color: 'secondary',
  marginRight: 8,
});

export const CurrentlySelectedItems = ({ selectedAlarms }) => {
  return (
    <SelectedItemsContainer>
      <SectionTitle variant="h6">{T.translate(LocaleKeys.messages.currentlySelectedStatusesAndAlarms)}</SectionTitle>
      <List dense component="div" role="list">
        {selectedAlarms?.map((alarm) => {
          return (
            <StyledListItem key={alarm?.id} role="listitem">
              <Box display="flex" flexDirection="row" alignItems="center">
                <DotPlaceholder color="primary" />
                <Typography> {alarm?.name}</Typography>
              </Box>
              <Divider />
            </StyledListItem>
          );
        })}
      </List>
    </SelectedItemsContainer>
  );
};
