import { useState, useEffect } from 'react';
import { getAlarmGroups, getAlarmGroupsAlarms } from 'Api/devices';

export const useAlarmsAndStatuses = ({ handleChange, values, mappingsInfo, deviceExternalId }) => {
  const [alarmGroups, setAlarmGroups] = useState(null);
  const [alarms, setAlarms] = useState(null);
  const [selectedAlarmGroup, setSelectedAlarmGroup] = useState(null);

  const addNewValuesToList = ({ valuesList, currentlySelectedAlarms }) => {
    valuesList.forEach((itemId) => {
      if (!currentlySelectedAlarms.some(({ id }) => id === itemId)) {
        const newAlarm = alarms.find(({ id }) => id === itemId);

        currentlySelectedAlarms = [...currentlySelectedAlarms, newAlarm];
      }
    });

    return currentlySelectedAlarms;
  };

  const removeValuesFromList = ({ valuesList, currentlySelectedAlarms }) => {
    valuesList.forEach((itemId) => {
      currentlySelectedAlarms = currentlySelectedAlarms.filter(({ id }) => id !== itemId);
    });

    return currentlySelectedAlarms;
  };

  const addOrRemoveSingleValueToList = ({ currentlySelectedAlarms, toggledItemId }) => {
    if (currentlySelectedAlarms.some(({ id }) => id === toggledItemId)) {
      currentlySelectedAlarms = currentlySelectedAlarms.filter(({ id }) => id !== toggledItemId);
    } else {
      const newAlarm = alarms.find(({ id }) => id === toggledItemId);

      currentlySelectedAlarms = [...currentlySelectedAlarms, newAlarm];
    }

    return currentlySelectedAlarms;
  };

  const onChange = ({ valuesList, toggleAllChecked, toggledItemId }) => {
    let currentlySelectedAlarms = [...values.selectedAlarms];

    if (toggleAllChecked) {
      currentlySelectedAlarms = addNewValuesToList({ valuesList, currentlySelectedAlarms });
    } else if (toggleAllChecked === false) {
      currentlySelectedAlarms = removeValuesFromList({ valuesList, currentlySelectedAlarms });
    } else if (toggleAllChecked === undefined && toggledItemId) {
      currentlySelectedAlarms = addOrRemoveSingleValueToList({ toggledItemId, currentlySelectedAlarms });
    }

    handleChange({
      target: { value: currentlySelectedAlarms, name: 'selectedAlarms' },
    });
  };

  const onItemClick = (itemId) => {
    if (values.selectedAlarms.some(({ id }) => id === itemId)) {
      const newSelectedAlarms = values.selectedAlarms.filter(({ id }) => id !== itemId);

      handleChange({
        target: { value: [...newSelectedAlarms], name: 'selectedAlarms' },
      });
    } else {
      const newAlarm = alarms.find(({ id }) => id === itemId);

      handleChange({
        target: { value: [...values.selectedAlarms, newAlarm], name: 'selectedAlarms' },
      });
    }
  };

  useEffect(() => {
    getAlarmGroups().then(({ data }) => {
      setAlarmGroups(data.alarmGroups);
    });
  }, []);

  useEffect(() => {
    if (selectedAlarmGroup) {
      getAlarmGroupsAlarms({ id: selectedAlarmGroup.externalId }, { deviceExternalId: deviceExternalId }).then(
        ({ data }) => {
          setAlarms(data);
        }
      );
    } else {
      setAlarms(null);
    }
  }, [selectedAlarmGroup]);

  return { onItemClick, alarmGroups, alarms, selectedAlarmGroup, onChange, setSelectedAlarmGroup };
};
