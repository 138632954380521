import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';

const DeleteDialog = ({ isOpen, disableConfirm, title, message, onConfirm, onCancel, content }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState();

  const handleConfirm = () => {
    setIsProcessing(true);
    setError(null);
    onConfirm?.()
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const handleCancel = () => {
    onCancel();
    setError(null);
  };

  return (
    <Dialog open={isOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {(!error && content) || (
          <DialogContentText id="alert-dialog-description">
            {error ? T.translate(LocaleKeys.messages.errorWhileProcessingRequest) : message}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {onCancel && (
          <Button onClick={handleCancel} color="primary" disabled={isProcessing}>
            {T.translate(LocaleKeys.labels.cancel)}
          </Button>
        )}
        {onConfirm && (
          <Button onClick={handleConfirm} color="primary" autoFocus disabled={disableConfirm || isProcessing || error}>
            {T.translate(LocaleKeys.labels.confirm)}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

DeleteDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  disableConfirm: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default DeleteDialog;
