import { useEffect, useState } from 'react';
import { getReverseGeocodeHERE } from 'Api/maps';
import LocaleKeys from 'Localization/LocaleKeys';
import PropTypes from 'prop-types';

export const useAddressFromGeocode = ({
  mapToken,
  latitude,
  longitude,
  id,
  rowsWithVisibleAddressIds,
  setRowsWithVisibleAddressIds,
}) => {
  const [showAddress, setShowAddress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [address, setAddress] = useState(null);

  const disabled =
    rowsWithVisibleAddressIds?.length >= 5 && !rowsWithVisibleAddressIds?.some((addressId) => addressId === id);

  const handleFetch = () => {
    setIsLoading(true);
    getReverseGeocodeHERE(latitude, longitude, mapToken)
      .then((response) => {
        if (response.items[0]?.title) {
          setAddress(response.items[0]?.title);
          setError(null);
        } else {
          setError(LocaleKeys.messages.noAddressForTheGivenGeolocation);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setAddress(null);
        setError(LocaleKeys.messages.errorWhileFetchingData, { name: 'geolocation' });
        setIsLoading(false);
      });
  };

  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!disabled) {
      if (rowsWithVisibleAddressIds) {
        if (rowsWithVisibleAddressIds?.includes(id)) {
          setRowsWithVisibleAddressIds(rowsWithVisibleAddressIds.filter((addressId) => addressId !== id));
          setShowAddress(false);
        } else {
          setRowsWithVisibleAddressIds([...rowsWithVisibleAddressIds, id]);
          setShowAddress(true);
          if (!disabled) {
            if (longitude && longitude) {
              handleFetch();
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if (rowsWithVisibleAddressIds?.includes(id)) {
      setShowAddress(true);
      if (!disabled) {
        if (longitude && longitude) {
          handleFetch();
        }
      }
    } else {
      setShowAddress(false);
    }
  }, [id]);

  return {
    error,
    address,
    handleClick,
    showAddress,
    isLoading,
    disabled,
  };
};

useAddressFromGeocode.propTypes = {
  onClick: PropTypes.func,
  onGeocodeChange: PropTypes.func,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  disabled: PropTypes.bool,
  mapToken: PropTypes.string,
};
