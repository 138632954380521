import * as Yup from 'yup';
import { getAlarmGroupsExistsByName } from 'Api/devices';
import { isAlphanumericOrSpecialSymbol } from 'Validators/SharedValidators';

let lastValue;
let initialValue;

export const alarmsGroupNameExist = async (value, id) => {
  if (isAlphanumericOrSpecialSymbol([' '])(value)) {
    const result = lastValue?.value === value ? lastValue?.result : await getAlarmGroupsExistsByName(value);

    if (id) {
      if (!initialValue || id !== initialValue?.id) {
        initialValue = { value, id };
      }
    } else {
      initialValue = undefined;
    }

    lastValue = { value, result };

    return initialValue?.value === value || !result?.data;
  }

  return false;
};

const AlarmsGroupValidators = Yup.object().shape({
  name: Yup.string()
    .required('thisFieldIsRequired')
    .max(50, 'nameCannotExceed50Characters')
    .alphanumericOrSpecialSymbol([' '])
    .test('deviceGroupNameExist', 'devicesGroupWithThisNameAlreadyExists', function (value) {
      return alarmsGroupNameExist(value, this.parent.externalId);
    }),
  alarms: Yup.array().min(1, 'thisFieldIsRequired'),
  description: Yup.string().optional().max(200, 'nameCannotExceed200Characters'),
});

export default AlarmsGroupValidators;
