import React from 'react';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import TableActionButton from 'Components/display/table/TableActionButton';
import { Edit, Delete, FileCopy } from '@material-ui/icons';
import { Permissions } from 'Constants/permissions';

export const getTableActionButtons = ({ onEditClick, onCopyClick, onDeleteClick, permissions }) => {
  const actionButtons = [];

  if (permissions[Permissions.CanEditAlarmGroup]) {
    const editItemButton = (
      <TableActionButton
        key="editItemButton"
        onClick={({ alarmGroupName }) => onEditClick?.(alarmGroupName)}
        clickparm={'alarmGroupName'}
        icon={<Edit />}
        tooltipText={T.translate(LocaleKeys.tooltips.editItem)}
      />
    );

    actionButtons.push(editItemButton);
  }
  if (permissions[Permissions.CanCopyAlarmGroup]) {
    const copyItemButton = (
      <TableActionButton
        key="copyItemButton"
        onClick={(name) => {
          onCopyClick?.({ name });
        }}
        clickparam={'alarmGroupName'}
        icon={<FileCopy />}
        tooltipText={T.translate(LocaleKeys.tooltips.copyItem)}
      />
    );

    actionButtons.push(copyItemButton);
  }
  if (permissions[Permissions.CanRemoveAlarmGroup]) {
    const deleteItemButton = (
      <TableActionButton
        key="deleteItemButton"
        onClick={(id) => {
          onDeleteClick?.({ id });
        }}
        clickparam={'id'}
        icon={<Delete />}
        tooltipText={T.translate(LocaleKeys.tooltips.deleteItem)}
      />
    );

    actionButtons.push(deleteItemButton);
  }

  return actionButtons;
};
